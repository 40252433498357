import axios from 'axios'
import { createSlice, createSelector } from '@reduxjs/toolkit'
import config from '../resources/config'
import { pinError } from './sharedActions'


const requestTimeout = config.common.backend.requestTimeout

// включаем статус "загрузка" с задержкой, чтобы не мигал экран если грузится быстро. Эта констаната определяет задержку
const SET_LOADING_TIMEOUT = 1000
export const SCHOOLBUS_STATION_SEARCH_BY_AREA_PAGE_REDUCER_KEY = 'schoolbusStationSearchByAreaPage'

const schoolbusStationSearchByAreaPageSlice = createSlice({
    name: SCHOOLBUS_STATION_SEARCH_BY_AREA_PAGE_REDUCER_KEY,
    initialState: {
        areas: [],
        areasLoaded: false,
        areasError: false,
        areasLoading: false,
        areasTimeout: false,
        areaId: null,

        schools: [],
        schoolsLoaded: false,
        schoolsError: false,
        schoolsLoading: false,
        schoolsTimeout: false,
        schoolId: null,

        routes: [],
        routesLoaded: false,
        routesError: false,
        routesLoading: false,
        routesTimeout: false,
        routeId: null,

        stations: [],
        stationsLoaded: false,
        stationsError: false,
        stationsLoading: false,
        stationsTimeout: false,
        stationId: null,

    },
    reducers: {
        clearErrors: (state, action) => {
            state.areasError = false
            state.schoolsError = false
            state.routesError = false
            state.stationsError = false
        },
        clearTimeouts: state => {
            state.areasTimeout = false
            state.schoolsTimeout = false
            state.routesTimeout = false
            state.stationsTimeout = false
        },

        setAreas: (state, { payload }) => {
            state.areasLoaded = true
            state.areas = payload
            state.areasError = false
            state.areasLoading = false
            state.areasTimeout = false
        },
        clearAreas: (state, action) => {
            state.areasLoaded = false
            state.areas = false
            state.areasError = false
            state.areasLoading = false
            state.areasTimeout = false
            state.areaId = null
        },
        setAreasError: (state, action) => {
            state.areasError = true
            state.areasLoading = false
        },
        setAreasLoading: (state, { payload }) => {
            state.areasLoading = true
        },
        setAreasTimeout: state => {
            state.areasTimeout = true
        },

        setSchools: (state, { payload: { schoolId, schools } }) => {
            state.schoolId = schoolId
            state.schools = schools
            state.schoolsLoaded = true
            state.schoolsError = false
            state.schoolsLoading = false
            state.schoolsTimeout = false
        },
        clearSchools: (state, action) => {
            state.schoolsLoaded = false
            state.schoolId = null
            state.schools = []
            state.schoolsError = false
            state.schoolsLoading = false
            state.schoolsTimeout = false
        },
        setSchoolsError: (state, action) => {
            state.schoolsLoading = false
            state.schoolsError = true
        },
        setSchoolsLoading: (state, { payload }) => {
            state.schoolsLoading = true
        },
        setSchoolsTimeout: state => {
            state.schoolsTimeout = true
        },

        setRoutes: (state, { payload: { routes } }) => {
            state.routes = routes
            state.routesLoaded = true
            state.routesError = false
            state.routesLoading = false
            state.routesTimeout = false
        },
        clearRoutes: (state, action) => {
            state.routesLoaded = false
            state.routes = []
            state.routesError = false
            state.routesLoading = false
            state.routesTimeout = false
            state.routeId = null
        },
        setRoutesError: (state, action) => {
            state.routesLoading = false
            state.routesError = true
        },
        setRoutesLoading: (state, { payload }) => {
            state.routesLoading = true
        },
        setRoutesTimeout: state => {
            state.routesTimeout = true
        },
        setRouteId: (state, { payload: { routeId } }) => {
            state.routeId = routeId
        },
        setStations: (state, { payload: { stations } }) => {
            state.stations = stations
            state.stationsLoaded = true
            state.stationsError = false
            state.stationsLoading = false
            state.stationsTimeout = false
        },
        clearStations: (state, action) => {
            state.stationsLoaded = false
            state.stations = []
            state.stationsError = false
            state.stationsLoading = false
            state.stationsTimeout = false
            
        },
        setStationsError: (state, action) => {
            state.stationsLoading = false
            state.stationsError = true
        },
        setStationsLoading: (state, { payload }) => {
            state.stationsLoading = true
        },
        setStationsTimeout: state => {
            state.stationsTimeout = true
        },

        setLoadingStarted: (state, { payload }) => {
            state[payload] = true
        },
    },
})

export const { clearErrors, clearTimeouts, setLoadingStarted, clearRoutes, clearAreas, clearStations, clearSchools } =
    schoolbusStationSearchByAreaPageSlice.actions

export const getSchoolbusStationSearchByAreaPage = state =>
    state.pages[SCHOOLBUS_STATION_SEARCH_BY_AREA_PAGE_REDUCER_KEY]

export const getAreaId = state => getSchoolbusStationSearchByAreaPage(state).areaId
export const getAreas = state => getSchoolbusStationSearchByAreaPage(state).areas
export const getAreasLoaded = state => getSchoolbusStationSearchByAreaPage(state).areasLoaded
export const getAreasLoading = state => getSchoolbusStationSearchByAreaPage(state).areasLoading
export const getAreasError = state => getSchoolbusStationSearchByAreaPage(state).areasError
export const getAreasTimeout = state => getSchoolbusStationSearchByAreaPage(state).areasTimeout

export const getSchoolId = state => getSchoolbusStationSearchByAreaPage(state).schoolId
export const getSchools = state => getSchoolbusStationSearchByAreaPage(state).schools
export const getSchoolsLoaded = state => getSchoolbusStationSearchByAreaPage(state).schoolsLoaded
export const getSchoolsLoading = state => getSchoolbusStationSearchByAreaPage(state).schoolsLoading
export const getSchoolsError = state => getSchoolbusStationSearchByAreaPage(state).schoolsError
export const getSchoolsTimeout = state => getSchoolbusStationSearchByAreaPage(state).schoolsTimeout

export const getRoutes = state => getSchoolbusStationSearchByAreaPage(state).routes
export const getRoutesLoaded = state => getSchoolbusStationSearchByAreaPage(state).routesLoaded
export const getRoutesLoading = state => getSchoolbusStationSearchByAreaPage(state).routesLoading
export const getRoutesError = state => getSchoolbusStationSearchByAreaPage(state).routesError
export const getRoutesTimeout = state => getSchoolbusStationSearchByAreaPage(state).routesTimeout
export const getRouteId = state => getSchoolbusStationSearchByAreaPage(state).routeId

export const getStations = state => getSchoolbusStationSearchByAreaPage(state).stations
export const getStationsLoaded = state => getSchoolbusStationSearchByAreaPage(state).stationsLoaded
export const getStationsLoading = state => getSchoolbusStationSearchByAreaPage(state).stationsLoading
export const getStationsError = state => getSchoolbusStationSearchByAreaPage(state).stationsError
export const getStationsTimeout = state => getSchoolbusStationSearchByAreaPage(state).stationsTimeout

export const getLoading = createSelector(
    [getAreasLoading, getSchoolsLoading, getRoutesLoading],
    (areasLoading, schoolsLoading, routesLoading) => areasLoading || schoolsLoading || routesLoading,
)

export const getAreasReady = createSelector(
    [getAreasLoaded, getAreasError, getAreasLoading],
    (areasLoaded, hasError, loading) => areasLoaded && !hasError && !loading,
)

export const getSchoolsReady = createSelector(
    [getSchoolsLoaded, getSchoolsError, getSchoolsLoading],
    (schoolsLoaded, hasError, loading) => schoolsLoaded && !hasError && !loading,
)

export const getRoutesReady = createSelector(
    [getRoutesLoaded, getRoutesError, getRoutesLoading],
    (routesLoaded, hasError, loading) => routesLoaded && !hasError && !loading,
)

export const getStationsReady = createSelector(
    [getStationsLoaded, getStationsError, getStationsLoading],
    (stationsLoaded, hasError, loading) => stationsLoaded && !hasError && !loading,
)

// включаем статус "загрузка" с задержкой, чтобы не мигал экран если грузится быстро
export const startLoading = loadingType => async (dispatch, getState) => {
    dispatch(clearErrors())
    dispatch(clearTimeouts())
    return setTimeout(() => {
        dispatch(setLoadingStarted(loadingType))
    }, SET_LOADING_TIMEOUT)
} //TODO: может сделать как в другом срезе

export const fetchAreas = () => async (dispatch, getState) => {
    const state = getSchoolbusStationSearchByAreaPage(getState())
    if (Array.isArray(state.areas) && state.areas.length) {
        return
    }

    const loadingTimeout = await dispatch(startLoading('areasLoading'))

    try {
        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }
        const { data } = await axios
            .get('/getAreas.php', {
                params: {},
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            }) // TODO: исправить обработку ошибок. Сейчас если здесь 404, то падает весь сайт.

        if (data.error) {
            if (data.error === 'incorrect pin') {
                dispatch(pinError(data.error))
                throw new Error('Неверный пин')
            }
            throw new Error('Ошибка ответа сервера')
        }

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        dispatch(schoolbusStationSearchByAreaPageSlice.actions.setAreas(data))
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения муниципальных образований', err)
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.clearAreas())
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.setAreasError())
        if (err?.message === 'request timeout') {
            dispatch(schoolbusStationSearchByAreaPageSlice.actions.setAreasTimeout())
        }
    } finally {
        clearTimeout(loadingTimeout)
    }
}

export const fetchSchools = aid => async (dispatch, getState) => {
    const state = getSchoolbusStationSearchByAreaPage(getState())

    // если номер маршрута другой чистим старое перед загрузкой
    if (aid && aid !== state.schoolId) {
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.clearSchools())
    } else {
        // если номер маршрута такой же ничего не грузим заново
        return
    }

    const loadingTimeout = await dispatch(startLoading('schoolsLoading'))

    try {
        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }
        const { data } = await axios
            .get('/getAreas.php', {
                params: {
                    aid: aid,
                },
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            })

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        dispatch(
            schoolbusStationSearchByAreaPageSlice.actions.setSchools({
                schoolId: aid,
                schools: data,
            }),
        )
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения школ ', err)
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.clearSchools())
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.setSchoolsError())
        if (err?.message === 'request timeout') {
            dispatch(schoolbusStationSearchByAreaPageSlice.actions.setSchoolsTimeout())
        }
    } finally {
        clearTimeout(loadingTimeout)
    }
}

export const fetchRoutes = sid => async (dispatch, getState) => {
    const state = getSchoolbusStationSearchByAreaPage(getState())

    // грузим данные
    const loadingTimeout = await dispatch(startLoading('routesLoading'))

    try {
        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }
        const { data } = await axios
            .get('/getAreaRoutes.php', {
                params: {
                    aid: sid,
                },
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            })

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        dispatch(
            schoolbusStationSearchByAreaPageSlice.actions.setRoutes({
                routeId: sid,
                routes: data,
            }),
        )
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения маршрутов ', err)
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.clearRoutes())
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.setRoutesError())
        if (err?.message === 'request timeout') {
            dispatch(schoolbusStationSearchByAreaPageSlice.actions.setRoutesTimeout())
        }
    } finally {
        clearTimeout(loadingTimeout)
    }
}

export const fetchStations = sid => async (dispatch, getState) => {
    const state = getSchoolbusStationSearchByAreaPage(getState())

    // грузим данные
    const loadingTimeout = await dispatch(startLoading('routesLoading'))

    try {
        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }

        const { data } = await axios
            .get('/getRouteStations.php', {
                params: {
                    id: sid,
                },
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            })

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        const stationsIds = data.map(item => {
            return item.station_id
        })

        dispatch(
            schoolbusStationSearchByAreaPageSlice.actions.setStations({
                stations: data,
            }),
        )
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения маршрутов ', err)
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.clearStations())
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.setStationsError())
        if (err?.message === 'request timeout') {
            dispatch(schoolbusStationSearchByAreaPageSlice.actions.setStationsTimeout())
        }
    } finally {
        clearTimeout(loadingTimeout)
    }
}

export const fetchStationsWithSchedule = sid => async (dispatch, getState) => {
    const state = getSchoolbusStationSearchByAreaPage(getState())

    // грузим данные
    const loadingTimeout = await dispatch(startLoading('routesLoading'))

    try {
        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }

        const { data } = await axios
            .get('/getRouteStationsWithSchedule.php', {
                params: {
                    id: sid,
                },
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            })

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        const stationsIds = data.map(item => {
            return item.station_id
        })

        dispatch(
            schoolbusStationSearchByAreaPageSlice.actions.setStations({
                stations: data,
            }),
        )
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения маршрутов ', err)
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.clearStations())
        dispatch(schoolbusStationSearchByAreaPageSlice.actions.setStationsError())
        if (err?.message === 'request timeout') {
            dispatch(schoolbusStationSearchByAreaPageSlice.actions.setStationsTimeout())
        }
    } finally {
        clearTimeout(loadingTimeout)
    }
}

export default schoolbusStationSearchByAreaPageSlice.reducer
