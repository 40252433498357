import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import StationSearchPageTitle from '../../components/StationSearch/PageTitle'
import StationsList from '../../components/StationSearch/StationsList'
import LoadingScreen from '../../components/StationSearch/LoadingScreen'
import ErrorScreen from '../../components/StationSearch/ErrorScreen'
import {
    getHistory,
    getHistoryStationsList,
    loadHistoryStations,
    getHasStationsError,
    getStationsLoading,
    getStationsReady,
    getStationsTimeout
} from '../../store/historyPageSlice'

import EmptyScreen from './EmptyScreen'

/* eslint react/prop-types: ["off"] */
const HistoryPage = ({
    history,
    stations,
    hasError,
    loadHistoryStations,
    stationsLoading,
    stationsReady,
    stationsTimeout
}) => {
    const intl = useIntl()

    useEffect(() => {
        loadHistoryStations()
    }, [history, loadHistoryStations])

    const hasItems = Array.isArray(history) && history.length > 0

    return (
        <>
            <StationSearchPageTitle
                title={intl.formatMessage({
                    id: 'pages.historyPage.title',
                })}
                headTitle={intl.formatMessage({
                    id: 'pages.historyPage.htmlHead.title',
                })}
            />
            {!hasItems ? (
                <EmptyScreen />
            ) : (
                <>
                    {hasError ? (
                        <ErrorScreen
                            message={intl.formatMessage(
                                stationsTimeout ?
                                    { id: "default.timeoutScreen" }
                                    : { id: 'pages.historyPage.ErrorScreen' }
                                    , {
                                        br: <br key={0} />,
                                    }
                            )}
                        />
                    ) : (
                        <>
                            {!stationsLoading ? (
                                <StationsList
                                    stations={stations}
                                    notFoundMessage={
                                        intl.formatMessage({
                                            id: 'pages.historyPage.EmptyScreen'
                                        })
                                    }
                                />
                            ) : (
                                <LoadingScreen
                                    message={intl.formatMessage({
                                        id: 'pages.historyPage.LoadingScreen',
                                    })}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default connect(
    state => ({
        history: getHistory(state),
        stations: getHistoryStationsList(state),
        hasError: getHasStationsError(state),
        stationsLoading: getStationsLoading(state),
        stationsReady: getStationsReady(state),
        stationsTimeout: getStationsTimeout(state)
    }),
    {
        loadHistoryStations,
    },
)(HistoryPage)
