import React from 'react'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useGetQueryParams } from '../../services/useGetQuery'

import translit from './../../services/transliterator'

import { getRoutes } from '../../store/schoolbusStationSearchByAreaPageSlice'
import { IconArrowForward, IconSearchByType } from '../../resources/icons'
import config from '../../resources/config'

import './TypeSelector.school.scss'
import { setSchedule } from '../../store/hasScheduleSlice'

const { routeNameReplacerRegexpBody, routeNameReplacerRegexpFlags, routeNameReplacerText } =
    config.pages.schoolbusSearchByAreaPage

const routeNameReplacerRegexp = new RegExp(routeNameReplacerRegexpBody, routeNameReplacerRegexpFlags)

const replaceRouteName = routeName => {
    let result = routeName

    if (routeName) {
        result = routeNameReplacerRegexpBody
            ? routeName.replace(routeNameReplacerRegexp, routeNameReplacerText || '')
            : routeName
    }

    return result
}

/* eslint react/prop-types: ["off"] */
const TypeSelector = ({ routes }) => {
    const intl = useIntl()
    const routeMatch = useRouteMatch()
    const { schid, aid } = useGetQueryParams()
    const location = useLocation()
    const dispatch = useDispatch()
    //const {schedule} = useSelector((store) => store.hasSchedule);

    return (
        <>
            <div className="ssbtp-type-selector-title">
                {intl.formatMessage({
                    id: 'pages.schoolbusStationSearchByArea.routesList.title',
                })}
            </div>
            <div className="ssbtp-type-selector-wrapper">
                {routes.map(route => console.log(route))}
                {routes.map(route => (
                    <Link
                        key={route.id}
                        to={
                            route && route?.has_schedule
                                ? `${routeMatch.path}?aid=${aid}&schid=${schid}&rid=${route.id}`
                                : `/schoolbus_map${location.search}&routes=${route.id}&usemap=1`
                        }
                        onClick={() => {
                            console.log('loging', route?.has_schedule)
                            dispatch(setSchedule(route?.has_schedule))
                            //console.log(schedule)
                        }}
                        className="link-item"
                    >
                        <div className="selector-item">
                            <div className="selector-item-title">
                                <div className="badge">
                                    <IconSearchByType />
                                </div>
                                <div className="text">{translit().transliterate(replaceRouteName(route.name))}</div>
                            </div>
                            <IconArrowForward />
                        </div>
                    </Link>
                ))}
            </div>
        </>
    )
}

export default connect(state => ({
    routes: getRoutes(state),
}))(TypeSelector)
