import axios from 'axios'
import { createSlice } from '@reduxjs/toolkit'
import config from '../resources/config'
import { pinError } from './sharedActions'
const requestTimeout = config.common.backend.requestTimeout

export const STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY = 'stationSearchByLocationPage'

const stationSearchByLocationPageSlice = createSlice({
    name: STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY,
    initialState: {
        stationsLoaded: false,
        stationsLoading: false,
        error: null,
        stations: [],
        stationsTimeout: false,
    },
    reducers: {
        startLoading: (state, action) => {
            state.error = null
            state.stationsLoading = true
            state.stations = []
            state.stationsLoaded = false
            state.stationsTimeout = false
        },
        errorLoading: (state, { payload }) => {
            state.stationsLoading = false
            state.stations = []
            state.stationsLoaded = false
            state.error = payload
        },
        setStations: (state, { payload }) => {
            state.error = null
            state.stations = payload
            state.stationsLoaded = true
            state.stationsLoading = false
            state.stationsTimeout = false
        },
        setStationsTimeout: state => {
            state.stationsTimeout = true
        },
    },
})

export const getPage = state => state.pages[STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY]
export const getStations = state => getPage(state).stations
export const getStationsLoaded = state => getPage(state).stationsLoaded
export const getStationsLoading = state => getPage(state).stationsLoading
export const getErrors = state => getPage(state).error
export const getStationsTimeout = state => getPage(state).stationsTimeout

export const fetchStationsByLocation = () => async (dispatch, getState) => {
    dispatch(stationSearchByLocationPageSlice.actions.startLoading())

    if (!window.navigator || !window.navigator.geolocation) {
        dispatch(stationSearchByLocationPageSlice.actions.errorLoading('GeolocationError'))
    }

    let position
    // первым шагом получаем геолокацию пользователя
    try {
        position = await getGeoposition()
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения геопозиции пользователя', err)
    }

    // если нет геолокации - ошибка
    if (!position) {
        dispatch(stationSearchByLocationPageSlice.actions.errorLoading('GeolocationError'))
        return
    }

    // по геолокации получаем остановки
    try {
        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }

        const { data } = await axios
            .get('/searchStation.php', {
                params: {
                    ...position,
                },
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            })

        if (data.error) {
            if (data.error === 'incorrect pin') {
                dispatch(pinError(data.error))
                throw new Error('Неверный пин')
            } 
            throw new Error("Ошибка ответа сервера")
        }

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        data.sort((a, b) => a.dist - b.dist)

        dispatch(stationSearchByLocationPageSlice.actions.setStations(data))
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения остановок по геолокации', err)
        dispatch(stationSearchByLocationPageSlice.actions.errorLoading('ServerError'))
        if (err?.message === 'request timeout') {
            dispatch(stationSearchByLocationPageSlice.actions.setStationsTimeout())
        }
    }
}

// async/await (promise) обертка для получения геолокации
function getGeoposition() {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => {
                resolve({ lat: position.coords.latitude, lng: position.coords.longitude })
            },
            err => {
                reject()
            },
        )
    })
}

export default stationSearchByLocationPageSlice.reducer
