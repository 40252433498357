import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useRouteMatch, Redirect, Route, Switch } from 'react-router-dom'

import { getStationInfo, pageOpened, pageClosed, clearSchedules } from '../../store/schoolBusSchedulePageSlice'
import { useGetQueryParams } from "../../services/useGetQuery"
import { addToHistory } from '../../store/historyPageSlice'

import StationInfo from "./StationInfo"
import ScheduleView from "./ScheduleView"


import MapView from "../SchoolbusStationSearchByAreaPage/MapView"
import LoadingScreen from '../../components/StationSearch/LoadingScreen'
import ErrorScreen from '../../components/StationSearch/ErrorScreen'


const SchoolbusStationSchedulePage = ({ 
  stationInfo, 
  pageClosed, 
  pageOpened, 
  addToHistory, 
  clearSchedules 
}) => {
  const routeMatch = useRouteMatch()
  const { sid, date } = useGetQueryParams()

  useEffect(() => {
    return () => {
      clearSchedules()
    }
  }, [])

  useEffect(() => {
    pageOpened({ stationId: sid, date: date })
    return (() => {
      pageClosed()
    })
  }, [pageOpened, pageClosed, sid, date])

  useEffect(() => {
    if (sid > 0 && stationInfo) {
      addToHistory(sid)
    }
  }, [sid, stationInfo, addToHistory])

  return (
    <>
      <StationInfo />
      <Switch>
        <Route path={'/schoolbus_station_schedule'} exact>
          <ScheduleView />
        </Route>
        <Route>
          <Redirect to={'/schoolbus_station_schedule'} />
        </Route>
      </Switch>

    </>
  )
}

export default connect(
  state => ({
    stationInfo: getStationInfo(state),
  }),
  ({
    pageOpened,
    pageClosed,
    addToHistory,
    clearSchedules
  })
)(SchoolbusStationSchedulePage)