import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
  color: PropTypes.string,
}

const Svg = ({ color }) => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" d="M5.29288 11.7069C5.48838 11.9024 5.74388 11.9999 5.99988 11.9999C6.25588 11.9999 6.51137 11.9024 6.70687 11.7069C7.09737 11.3164 7.09737 10.6834 6.70687 10.2929L3.41388 6.99987H16.9999C17.5519 6.99987 17.9999 6.55187 17.9999 5.99987C17.9999 5.44787 17.5519 4.99987 16.9999 4.99987H3.41388L6.70687 1.70687C7.09737 1.31637 7.09737 0.683375 6.70687 0.292875C6.31637 -0.097625 5.68338 -0.097625 5.29288 0.292875L0.292875 5.29287C-0.097625 5.68337 -0.097625 6.31637 0.292875 6.70687L5.29288 11.7069Z" fill="#6C7882" />
  </svg>

)

Svg.propTypes = propTypes

export default Svg
