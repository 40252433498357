import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import HomePage from './pages/HomePage'
import StationForecastsPage from './pages/StationForecastPage'
import StationSearchByTypePage from './pages/StationSearchByTypePage'
import StationSearchByKeywordPage from './pages/StationSearchByKeywordPage'
import StationSearchByLocationPage from './pages/StationSearchByLocationPage'
import FavoritesPage from './pages/FavoritesPage'
import HistoryPage from './pages/HistoryPage'
import MapPage from './pages/MapPage'
import SchoolbusStationSchedulePage from './pages/SchoolbusStationSchedulePage'
import SchoolbusStationSearchByAreaPage from './pages/SchoolbusStationSearchByAreaPage'
import SchoolbusMap from './pages/SchoolbusMap'

export default () => (
    <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/station_forecasts" component={StationForecastsPage} />
        <Route path="/station_search_by_type" component={StationSearchByTypePage} />
        <Route path="/station_search_by_keyword" component={StationSearchByKeywordPage} />
        <Route path="/station_search_by_location" component={StationSearchByLocationPage} />
        <Route path="/map" component={MapPage} />
        <Route path="/favorites" component={FavoritesPage} />
        <Route path="/history" component={HistoryPage} />
        <Route path="/schoolbus_station_schedule" component={SchoolbusStationSchedulePage} />
        <Route path="/schoolbus_station_search_by_area" component={SchoolbusStationSearchByAreaPage} />
        <Route path="/schoolbus_map" component={SchoolbusMap} />
        {/* <Route>
            <Redirect to="/" />
        </Route> */}
    </Switch>
)
