import React, { useEffect, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, useLocation, useRouteMatch, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Leaflet from 'leaflet'
import { isEmpty } from 'lodash-es'
import * as dayjs from 'dayjs'
import * as dayjs_isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import { useGetQueryParams } from '../../services/useGetQuery'
import { useInterval } from '../../services/useInterval'
import config from '../../resources/config'

import TileLayerComponent from '../../widgets/MapComponents/TileLayer'
import VehicleLayerComponent from '../../widgets/MapComponents/SchoolbusLayer'
import RouteLineLayer from '../../widgets/MapComponents/RouteLayer'
import RouteStationsLayer from '../../widgets/MapComponents/RouteStationsLayer'

import SchoolBusDataSource from '../../store/SchoolBusDataSource'
import RouteNodesDataSource from '../../store/RouteNodesDataSource'
import RouteStationsDataSource from '../../store/RouteStationsDataSource'

import { getThemeColor } from '../../store/commonSlice'
import { THEME_COLOR } from '../../resources/config/theme-constants'

import { IconArrowLeftVariant3 } from '../../resources/icons'

import 'leaflet/dist/leaflet.css'
import './MapView.school.scss'

dayjs.extend(dayjs_isSameOrBefore)

const mapConfig = config.pages.mapPage

const MARKER_RTYPE_TO_TEXTURE = {
    Ав: {
        textureName: 'school_bus_marker',
    },
    А: {
        textureName: 'school_bus_marker',
    },
}

const TEXTURES_SRC = [
    {
        url: 'school_bus_marker.png',
        name: 'school_bus_marker',
    },
    {
        url: 'school_bus_1.png',
        name: 'school_bus_1',
    },
]

const TILES_SRC_URL = '//maps.bus62.ru/getTile.php?z={z}&x={x}&y={y}&e=@2x.png'

const tileLayerComponent = TileLayerComponent()
const vehicleLayerComponent = VehicleLayerComponent({
    config: mapConfig,
    dataSource: SchoolBusDataSource,
    componentOptions: {
        markerOptions: {
            type: 2,
        },
    },
})

const routeLayer = RouteLineLayer({
    config: config,
    dataSource: RouteNodesDataSource,
})

const routeStationsLayer = RouteStationsLayer({
    config: config,
    dataSource: RouteStationsDataSource,
})

const MapComponent = ({ themeColor, children }) => {
    const mapLeaflet = useRef(null)
    const mapElement = useRef(null)

    const isZooming = useRef(false)
    //const isWindowFocused = useRef(true)
    const currentZoomLevel = useRef(0)

    const query = useGetQueryParams()
    const routeMatch = useRouteMatch()
    const intl = useIntl()
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (mapElement.current) {
            if (isEmpty(mapLeaflet.current)) {
                mapLeaflet.current = Leaflet.map(mapElement.current, {
                    center: [mapConfig.initialMapPosition.lat, mapConfig.initialMapPosition.lng],
                    zoom: mapConfig.initialMapPosition.zoom,
                    attributionControl: true,
                    zoomControl: true,
                    doubleClickZoom: true,
                    scrollWheelZoom: true,
                    fadeAnimation: true,
                    dragging: true,
                    animate: true,
                    easeLinearity: 0.35,
                    renderer: Leaflet.canvas({ padding: 0.2 }),  ///
                    touchZoom: 'center',
                })

                mapLeaflet.current.on('zoomstart', () => {
                    isZooming.current = true
                })

                mapLeaflet.current.on('zoomend', () => {
                    isZooming.current = false

                    currentZoomLevel.current = mapLeaflet.current.getZoom()
                    routeStationsLayer.setZoom(currentZoomLevel.current)
                })

                setTimeout(() => {
                    mapLeaflet.current.invalidateSize()
                })

                //порядок слоёв отвечает за порядок отрисовки

                tileLayerComponent.createLayer({ mapRef: mapLeaflet.current, src: TILES_SRC_URL })

                routeLayer.createLayer({ mapRef: mapLeaflet.current })
                const bounds = routeLayer.getBounds()
                if (!isEmpty(bounds)) {
                    mapLeaflet.current.fitBounds(bounds)
                }

                // отрисовка остановок
                routeStationsLayer.createLayer({
                    mapRef: mapLeaflet.current,
                    options: {
                        onStationClick: onStationClick,
                    },
                })

                vehicleLayerComponent.createLayer({ mapRef: mapLeaflet.current })
                vehicleLayerComponent.setCallback(onSelectTransportItem)
                vehicleLayerComponent.loadTextures({
                    texturesSrc: TEXTURES_SRC,
                })

                // window.addEventListener("focus", onWindowFocus) //TODO: перейти на visibility api, что бы работало по человечески
                // window.addEventListener("blur", onWindowBlur)

                currentZoomLevel.current = mapLeaflet.current.getZoom()
                routeStationsLayer.setZoom(currentZoomLevel.current)
            }
        }
        return () => {
            tileLayerComponent.removeLayer()
            vehicleLayerComponent.removeLayer()
            mapLeaflet.current.off()
            mapLeaflet.current.remove()
            mapLeaflet.current = undefined
            // window.removeEventListener('focus', onWindowFocus)
            // window.removeEventListener('blur', onWindowBlur)
        }
    }, [])

    useEffect(() => {
        if (query.routes) {
            vehicleLayerComponent.resetAnimKey()
            updateTransportPositions()
            routeLayer.loadRouteLine(query.routes).then(result => {
                routeLayer.showRouteLine()
                const bounds = routeLayer.getBounds()
                if (!isEmpty(bounds)) {
                    mapLeaflet.current.fitBounds(bounds)
                }
            })
            routeStationsLayer.loadRouteStations(query.routes)
        }
    }, [query])

    useEffect(() => {
        if (themeColor === THEME_COLOR.dark) {
            tileLayerComponent.updateFilter(['invert:100%'])
            routeLayer.setColors(null, '#4A4A5C')
        }
        if (themeColor === THEME_COLOR.light) {
            tileLayerComponent.updateFilter(['invert:0%'])
            routeLayer.setColors()
        }
    }, [themeColor])

    // const onWindowFocus = () => {
    //   vehicleLayerComponent.resetAnimKey()
    //   vehicleLayerComponent.clearVehicleCache()
    //   isWindowFocused.current = true
    //   updateTransportPositions(true)
    // }

    // const onWindowBlur = () => {
    //   isWindowFocused.current = false
    // }

    function onStationClick(event) {
        if (event?.target?.options?.meta?.station_id) {
            const station = event?.target?.options?.meta
            history.push(
                `/schoolbus_station_schedule?sid=${station.station_id}&date=${dayjs(new Date()).format('YYYY-MM-DD')}`,
            )
        }
    }

    const onSelectTransportItem = (id, rnum, rtype, direction) => {
        console.log(id, rnum, rtype, direction)
    }

    const updateTransportPositions = async (seamlessly = false) => {
        //if (isWindowFocused.current) {
        let filters = {}
        if (query.routes) {
            filters.rids = query.routes.split(',')
        }

        vehicleLayerComponent.updateVehicles({
            isZooming: isZooming.current,
            MARKER_RTYPE_TO_TEXTURE: MARKER_RTYPE_TO_TEXTURE,
            filters: filters,
            seamlessly: seamlessly,
        })
        //}
    }

    const animateFrame = () => {
        // if (isWindowFocused.current) {
        //   vehicleLayerComponent.animateFrame({
        //     isZooming: isZooming.current,
        //     isWindowFocused: isWindowFocused.current,
        //     MARKER_RTYPE_TO_TEXTURE: MARKER_RTYPE_TO_TEXTURE,
        //   })
        // }
        vehicleLayerComponent.animateFrame({
            isZooming: isZooming.current,
            MARKER_RTYPE_TO_TEXTURE: MARKER_RTYPE_TO_TEXTURE,
        })
    }

    useInterval(updateTransportPositions, mapConfig.updateIntervalMS)
    useInterval(animateFrame, mapConfig.animationFrameTimeMS)

    return (
        <>
            {children}

            <div id={'transport-map'} ref={mapElement} className="transport-map school-bus-map"></div>
        </>
    )
}

export default connect(state => ({
    themeColor: getThemeColor(state),
}))(MapComponent)
