import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'

import { IconArrowForward } from '../../resources/icons'
import transliterator from '../../services/transliterator'
import {
    getAreas,
} from '../../store/schoolbusStationSearchByAreaPageSlice'

import './RoutesList.school.scss'

/* eslint react/prop-types: ["off"] */
const Component = ({ areas }) => {
    const intl = useIntl()
    const routeMatch = useRouteMatch()
    return (
        <>
            <div className="ssbtp-routes-lists-subtitle">
                {intl.formatMessage({ id: 'pages.schoolbusStationSearchByArea.areasList.title' })}
            </div>
            <div className="ssbtp-routes-lists-list-wrapper">
                {areas.map(area => (
                    <div key={area.id} className="list-item">
                        <div className="list-item-routes">
                            <Link
                                key={area.id}
                                to={`${routeMatch.path}?aid=${area.id}`}
                                className="list-item-route"
                            >
                                <div className="list-item-route-name">
                                    {transliterator().transliterate(area.name)}
                                </div>
                                <div className="icon-arrow-forward-wrapper">
                                    <IconArrowForward />
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default connect(state => ({
    areas: getAreas(state)
}))(Component)
