import { pageOpened, fetchStationInfo, fetchSchedules, pageClosed, clearSchedules, clearStationInfo } from './schoolBusSchedulePageSlice'

export const schoolBusSchedulePageMiddleware = (store) => {

    return next => (action) => {
        next(action)

        if (action.type === pageOpened.toString()) {
            store.dispatch(fetchStationInfo())
            store.dispatch(fetchSchedules())
        }


        if (action.type === pageClosed.toString()) {
            store.dispatch(clearSchedules())
            store.dispatch(clearStationInfo())
        }
    }
}
