import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { isEmpty } from "lodash-es"
import { DialogLineCheckbox, Dialog } from '../../components/Dialog'

import './LoginDialog.school.scss'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import PinInput from "../../components/PinInput"
import { authorize, getLoginError, getLoginErrorMessage, clearError } from "../../store/commonSlice"

const propTypes = {
  opened: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
}

const PIN_SIZE = 4
const ERROR_MESSAGE_TIMEOUT = 2000

/* eslint react/prop-types: ["error", { ignore: [transportsIconsVisible, setTransportsIconsVisible, transportsNumberVisible, setTransportsNumberVisible, themeColor, themeModifier, setTheme, tableHeaderVisible, setTableHeaderVisible, locale, setLocale] }] */
const LocaleDialogBody = ({
  opened,
  handleCloseDialog,
  authorize,
  error,
  errorMessage,
  clearError
}) => {
  const intl = useIntl()
  const pinInputRef = useRef(null)
  const errorMessageTimeout = useRef(null)

  const [pinCode, setPinCode] = useState(new Array(PIN_SIZE).fill(""))
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [savedErrorMessage, setSavedErrorMessage] = useState("")

  useEffect(() => {
    if (!opened) {
      if (pinCode) {
        setPinCode(oldData => new Array(PIN_SIZE).fill(""))
      }
    }
  }, [opened])

  useEffect(() => {
    if (error) {
      setPinCode(new Array(PIN_SIZE).fill(""))
      pinInputRef.current?.focus()
      showError()
    }
  }, [error])

  useEffect(()=>{
    if(errorMessage) {
      setSavedErrorMessage(errorMessage)
    }
  }, [errorMessage])

  useEffect(() => {
    let filled = pinCode.every(item => {
      return !isEmpty(item)
    })

    if (filled) {
      onLoginClick()
    }
  }, [pinCode])

  const showError = (message) => {
    setShowErrorMessage(true)

    if (errorMessageTimeout.current) {
      clearTimeout(errorMessageTimeout.current)
    }

    errorMessageTimeout.current = setTimeout(() => {
      setShowErrorMessage(false)
      clearError()
    }, ERROR_MESSAGE_TIMEOUT)
  }

  const onLoginClick = () => {
    authorize(pinCode.join(""))
  }

  const onPinInput = (data) => {
    setPinCode(oldData => data)
  }
  //TODO: сделать общий для сайта toast и в него выводить ошибки и оповещения? Может что-бы несколько за раз.
  return (
    <>
      {opened ?
        (<Dialog
          opened
          handleCloseDialog={handleCloseDialog}
          className="login-dialog"
          title={""}
        >
          <div className="title-section">
            <span className="title">{intl.formatMessage({ id: 'pages.dialogs.login.title' })}</span>
          </div>
          <div className="settings-section items">
            <PinInput
              onChange={onPinInput}
              onEndInput={onLoginClick}
              value={pinCode}
              ref={pinInputRef}
            />
          </div>
          <div className={`settings-section errorMessage ${showErrorMessage ? "show" : ""}`}>{savedErrorMessage}</div>
          <div className="settings-section">
            <PrimaryButton onClick={onLoginClick}>{intl.formatMessage({ id: 'pages.dialogs.login.button.login' })}</PrimaryButton>
          </div>
        </Dialog>)
        : null
      }
    </>
  )
}

const LocaleDialog = connect(
  (state) => ({
    errorMessage: getLoginErrorMessage(state),
    error: getLoginError(state)
  }),
  {
    authorize,
    clearError
  },
)(LocaleDialogBody)

LocaleDialogBody.propTypes = propTypes
LocaleDialog.propTypes = propTypes

export default LocaleDialog
