import axios from 'axios'
import { createSelector, createSlice } from '@reduxjs/toolkit'

export const MAP_PAGE_REDUCER_KEY = 'mapPage'

const mapPageSlice = createSlice({
    name: MAP_PAGE_REDUCER_KEY,
    initialState: {
    },
    reducers: {
    },
})

export const { } = mapPageSlice.actions

export const getMapPage = state => state.pages[MAP_PAGE_REDUCER_KEY]


export default mapPageSlice.reducer
