import Leaflet from 'leaflet'
import 'leaflet.tilelayer.colorfilter'

const TileLayerComponent = () => {
    let tileLayer = null

    const createLayer = ({ mapRef, src, options }) => {

        tileLayer = Leaflet.tileLayer.colorFilter(src, options)
        tileLayer.addTo(mapRef)
    }
    const removeLayer = () => {
        tileLayer?.remove()
        tileLayer = null
    }

    const updateFilter = (colorFilter = []) => {
        tileLayer?.updateFilter(colorFilter)
    }

    return { createLayer, removeLayer, updateFilter }
}

export default TileLayerComponent
