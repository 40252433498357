import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { isEmpty, isString } from 'lodash-es'
import { push as navigateTo } from 'connected-react-router'
import { pinError } from './sharedActions'

import config from '../resources/config'
const requestTimeout = config.common.backend.requestTimeout

export const COMMON_REDUCER_KEY = 'common'


const commonSlice = createSlice({
    name: COMMON_REDUCER_KEY,
    initialState: {
        locale: config[COMMON_REDUCER_KEY].locale,
        themeColor: config[COMMON_REDUCER_KEY].themeColor,
        themeModifier: config[COMMON_REDUCER_KEY].themeModifier,

        transportsIconsVisible: config[COMMON_REDUCER_KEY].transportsIconsVisible,
        transportsNumberVisible: config[COMMON_REDUCER_KEY].transportsNumberVisible,
        tableHeaderVisible: config[COMMON_REDUCER_KEY].tableHeaderVisible,

        error: null,

        pinCode: config[COMMON_REDUCER_KEY].pinCode,
        loginError: false,
        loginErrorMessage: null,
    },
    reducers: {
        setAvailableRouteTypes: (state, { payload }) => {
            state.availableRouteTypes = payload

            return state
        },
        setLocale: (state, { payload }) => {
            state.locale = payload

            return state
        },
        setTheme: (state, { payload: { themeColor, themeModifier } }) => {
            state.themeColor = themeColor
            state.themeModifier = themeModifier

            return state
        },
        setTransportsIconsVisible: (state, { payload }) => {
            state.transportsIconsVisible = payload

            return state
        },
        setTransportsNumberVisible: (state, { payload }) => {
            state.transportsNumberVisible = payload

            return state
        },
        setTableHeaderVisible: (state, { payload }) => {
            state.tableHeaderVisible = payload

            return state
        },
        setError: (state, { payload }) => {
            state.error = payload
        },

        setPinCode: (state, { payload }) => {
            state.pinCode = payload
        },
        setLoginError: (state, { payload }) => {
            state.loginError = payload
        },
        setLoginErrorMessage: (state, { payload }) => {
            state.loginErrorMessage = payload
        },
    },
})

export const {
    setAvailableRouteTypes,
    setLocale,
    setTheme,
    setTransportsIconsVisible,
    setTransportsNumberVisible,
    setTableHeaderVisible,
    setError,
} = commonSlice.actions

export const getCommon = state => state[COMMON_REDUCER_KEY]
export const getLocale = state => getCommon(state).locale
export const getThemeColor = state => getCommon(state).themeColor
export const getThemeModifier = state => getCommon(state).themeModifier
export const getAvailableRouteTypes = state => getCommon(state).availableRouteTypes
export const getError = state => getCommon(state).error

export const getTransportsIconsVisible = state => getCommon(state).transportsIconsVisible
export const getTransportsNumberVisible = state => getCommon(state).transportsNumberVisible
export const getTableHeaderVisible = state => getCommon(state).tableHeaderVisible

export const getPinCode = state => getCommon(state).pinCode
export const getLoginError = state => getCommon(state).loginError
export const getLoginErrorMessage = state => getCommon(state).loginErrorMessage

export const fetchAvailableRouteTypes = () => async dispatch => {
    try {
        const cancelationToken = axios.CancelToken.source()

        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }

        const { data } = await axios
            .get('/getAllRouteTypes.php', { cancelToken: cancelationToken.token })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            })

        if (data.error) {
            if (data.error === 'incorrect pin') {
                dispatch(pinError(data.error))
                throw new Error('Неверный пин')
            }
            throw new Error('Ошибка ответа сервера')
        }

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        dispatch(setAvailableRouteTypes(data))
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения типов транспорта', err)
        dispatch(setError('Ошибка получения типов транспорта'))
    }
}

export const authorize = pinCode => async dispatch => {
    if (isEmpty(pinCode)) {
        dispatch(commonSlice.actions.setLoginError(true))
        dispatch(commonSlice.actions.setLoginErrorMessage('Пин код не должен быть пуст'))
        return
    }

    if (!isString(pinCode)) {
        dispatch(commonSlice.actions.setLoginError(true))
        dispatch(commonSlice.actions.setLoginErrorMessage('Пин код имеет неверный формат'))
        return
    }

    if (pinCode.search(/([^\d])/) !== -1) {
        dispatch(commonSlice.actions.setLoginError(true))
        dispatch(commonSlice.actions.setLoginErrorMessage('Пин код должен состоять из цифр'))
        return
    }

    if (pinCode.length != 4) {
        dispatch(commonSlice.actions.setLoginError(true))
        dispatch(commonSlice.actions.setLoginErrorMessage('Пин код должен состоять из четырёх цифр'))
        return
    }

    /*     dispatch(commonSlice.actions.setPinCode(pinCode))
        dispatch(commonSlice.actions.setLoginError(false))
        dispatch(commonSlice.actions.setLoginErrorMessage(null))
    
        return */

    const cancelationToken = axios.CancelToken.source()
    let timeout = null
    if (requestTimeout) {
        timeout = setTimeout(() => {
            cancelationToken.cancel('request timeout')
            dispatch(commonSlice.actions.setLoginError(true))
            dispatch(commonSlice.actions.setLoginErrorMessage('Превышено вермя ожидания авторизации'))
        }, requestTimeout)
    }
    axios
        .get('/checkPin.php', {
            params: {
                pincode: pinCode,
            },
            cancelToken: cancelationToken.token,
        })
        .then(result => {
            if (timeout) {
                clearTimeout(timeout)
            }
            const data = result.data
            if (data.error) {
                if (data.error === 'incorrect pin') {
                    dispatch(commonSlice.actions.setLoginError(true))
                    dispatch(commonSlice.actions.setLoginErrorMessage('Неверный пин код'))
                } else {
                    dispatch(commonSlice.actions.setLoginError(true))
                    dispatch(commonSlice.actions.setLoginErrorMessage('Ошибка авторизации'))
                    console.error('Ошибка авторизации ', error)
                }
            } else {
                dispatch(commonSlice.actions.setPinCode(pinCode))
                dispatch(commonSlice.actions.setLoginError(false))
                dispatch(commonSlice.actions.setLoginErrorMessage(null))
            }
        })
        .catch(error => {
            dispatch(commonSlice.actions.setLoginError(true))
            dispatch(commonSlice.actions.setLoginErrorMessage('Ошибка авторизации'))
            console.error('Ошибка авторизации ', error)
        })
}
;``
export const deauthorize = () => async dispatch => {
    dispatch(commonSlice.actions.setPinCode(null))
    dispatch(commonSlice.actions.setLoginError(false))
    dispatch(commonSlice.actions.setLoginErrorMessage(null))
    dispatch(navigateTo('/'))
}

export const clearError = () => async dispatch => {
    dispatch(commonSlice.actions.setLoginError(false))
    dispatch(commonSlice.actions.setLoginErrorMessage(null))
}

export default commonSlice.reducer
