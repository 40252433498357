import reduce from 'lodash-es/reduce'
import get from 'lodash-es/get'
import set from 'lodash-es/set'
import defaults from 'lodash-es/defaults'
import isBoolean from 'lodash-es/isBoolean'
import isNumber from 'lodash-es/isNumber'
import isString from 'lodash-es/isString'
import isArray from 'lodash-es/isArray'

import { defaultsConfigMap } from './01_defaultsConfig'
import { buildConfig } from './02_buildConfig'
import { userConfigMap } from './03_userConfig'
import { queryConfigMap } from './04_queryConfig'


// Merge all config pairs
const resultConfigMap = defaults({}, queryConfigMap, userConfigMap, buildConfig, defaultsConfigMap)
// Ger real config object

const configFinal = reduce(resultConfigMap, (configFinal, value, path) => {
    // Есть вероятность, что параметр пришел из localStorage или из query параметров. Нужно привести к требуемому типу
    if (isString(value)) {
        const defaultValue = get(defaultsConfigMap, path)

        if (isBoolean(defaultValue)) {
            value = value === 'true'
        } else if (isNumber(defaultValue)) {
            value = +value
        } else if (isArray(defaultValue)) {
            value = JSON.parse(value)
        }
    }

    set(configFinal, path, value)

    return configFinal
}, {})

export default configFinal
