import axios from 'axios'
import { createSlice } from '@reduxjs/toolkit'
import config from '../resources/config'
import { pinError } from './sharedActions'

const requestTimeout = config.common.backend.requestTimeout
export const STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY = 'stationSearchByKeywordPage'

const stationSearchByKeywordPageSlice = createSlice({
    name: STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY,
    initialState: {
        stationsLoaded: false,
        stationsLoading: false,
        hasError: false,
        stations: [],
        stationsTimeout: false,
    },
    reducers: {
        setStations: (state, { payload }) => {
            state.hasError = false
            state.stations = payload
            state.stationsLoaded = true
            state.stationsLoading = false
            state.stationsTimeout = false
        },
        startLoading: (state, action) => {
            state.hasError = false
            state.stationsLoading = true
            state.stations = []
            state.stationsLoaded = false
            state.stationsTimeout = false
        },
        errorLoading: (state, action) => {
            state.hasError = true
            state.stationsLoading = false
            state.stations = []
            state.stationsLoaded = false
        },
        clearStations: (state, action) => {
            state.hasError = false
            state.stations = []
            state.stationsLoaded = false
            state.stationsTimeout = false
        },
        setStationsTimeout: state => {
            state.stationsTimeout = true
        },
    },
})

export const { clearStations } = stationSearchByKeywordPageSlice.actions

export const getPage = state => state.pages[STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY]
export const getStations = state => getPage(state).stations
export const getStationsLoaded = state => getPage(state).stationsLoaded
export const getStationsLoading = state => getPage(state).stationsLoading
export const getHasError = state => getPage(state).hasError
export const getStationsTimeout = state => getPage(state).stationsTimeout

export const fetchStationsByKeyworld = query => async (dispatch, getState) => {
    dispatch(stationSearchByKeywordPageSlice.actions.startLoading())

    try {
        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }

        const { data } = await axios
            .get('/searchStation.php', {
                params: {
                    q: query,
                },
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            })

        if (data.error) {
            if (data.error === 'incorrect pin') {
                dispatch(pinError(data.error))
                throw new Error('Неверный пин')
            }
            throw new Error('Ошибка ответа сервера')
        }

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        dispatch(stationSearchByKeywordPageSlice.actions.setStations(data))
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения остановок по ключевому слову', err)
        dispatch(stationSearchByKeywordPageSlice.actions.errorLoading())
        if (err?.message === 'request timeout') {
            dispatch(stationSearchByKeywordPageSlice.actions.setStationsTimeout())
        }
    }
}

export default stationSearchByKeywordPageSlice.reducer
