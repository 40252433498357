import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BackButton } from '../../BackButton'

import './PageTitle.school.scss'

const propTypes = {
    title: PropTypes.string,
    headTitle: PropTypes.string,
}

const StationSearchPageTitle = ({ title, headTitle }) => {
    const { aid } = useSelector(state => state.router.location.query)

    const location = useLocation()

    const isTitleOnMap = useMemo(() => location.pathname === '/schoolbus_map', [location])

    return (
        <>
            <Helmet>
                <meta name="description" content={headTitle} />
                <title>{headTitle}</title>
            </Helmet>

            <div className="ss-title-wrapper">
                {isTitleOnMap
                ? (
                    <BackButton link={`/schoolbus_station_search_by_area?aid=${aid}`} title={title} />
                ) 
                : (
                    <div className="title">{title}</div>
                )}
            </div>
        </>
    )
}

StationSearchPageTitle.propTypes = propTypes

export default StationSearchPageTitle
