import { createSlice } from '@reduxjs/toolkit';

export const scheduleSlice = createSlice({
  name: 'hasSchedule',
  initialState: {
    schedule: true
  },
  reducers: {
    setSchedule: (state, action) => { state.schedule = action.payload }
  },
})

export const {
    setSchedule
} = scheduleSlice.actions;

export default scheduleSlice.reducer;