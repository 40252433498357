import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'

import { useGetQueryParams } from '../../services/useGetQuery'
import {
    fetchAreas,
    fetchRoutes,
    fetchSchools,
    fetchStationsWithSchedule,
    clearRoutes,
    clearSchools,
    clearStations,
    getAreas,
    getSchools,
    getRoutes,
    getRoutesReady,
    getSchoolsReady,
    getAreasReady,
    getStationsReady,
    getLoading,
    getAreasError,
    getSchoolsError,
    getRoutesError,
    getStationsError,
    getAreasTimeout,
    getSchoolsTimeout,
    getRoutesTimeout,
    getStationsTimeout,
} from '../../store/schoolbusStationSearchByAreaPageSlice'
import StationSearchPageTitle from '../../components/StationSearch/PageTitle'
import LoadingScreen from '../../components/StationSearch/LoadingScreen'
import ErrorScreen from '../../components/StationSearch/ErrorScreen'
import { IconBack, IconArrowLeftVariant3 } from '../../resources/icons'

import AreasList from './AreasList'
import SchoolsList from './SchoolsList'
import RoutesList from './RoutesList'
import StationsList from "./StationsList"
import './StationSearchByTypePage.school.scss'
import transliterator from '../../services/transliterator'
import config from '../../resources/config'
import MapView from './MapView'

const {
    routeNameReplacerRegexpBody,
    routeNameReplacerRegexpFlags,
    routeNameReplacerText
} = config.pages.schoolbusSearchByAreaPage

const routeNameReplacerRegexp = new RegExp(routeNameReplacerRegexpBody, routeNameReplacerRegexpFlags)

const replaceRouteName = (routeName) => {
    let result = routeName

    if (routeName) {
        result = routeNameReplacerRegexpBody ?
            routeName.replace(
                routeNameReplacerRegexp
                ,
                routeNameReplacerText || ""
            )
            : routeName
    }

    return result
}


/* eslint react/prop-types: ["off"] */
const StationSearchPageByType = ({
    areas,
    schools,
    routes,
    routesReady,
    schoolsReady,
    areasReady,
    stationsReady,
    loading,
    areasError,
    schoolsError,
    routesError,
    stationsError,
    areasTimeout,
    schoolsTimeout,
    routesTimeout,
    stationsTimeout,
    fetchAreas,
    fetchRoutes,
    fetchSchools,
    fetchStationsWithSchedule,
    clearRoutes,
    clearSchools,
    clearStations
}) => {
    const intl = useIntl()
    const { aid, schid, rid, routes: rids, usemap, sid } = useGetQueryParams()
    const routeMatch = useRouteMatch()
    const location = useLocation()


    useEffect(() => {
        fetchAreas()

        if (aid) {
            fetchSchools(aid)
        } else {
            clearSchools()
        }
    }, [fetchAreas, fetchSchools, aid])

    useEffect(() => {
        if (schid) {
            fetchRoutes(schid)
        } else {
            clearRoutes()
        }
    }, [fetchRoutes, schid])

    useEffect(() => {
        if (rid) {
            fetchStationsWithSchedule(rid)
        } else {
            clearStations()
        }
    }, [fetchStationsWithSchedule, rid])

    //TODO: пересмотреть роутинг на этой странице. 
    return (
        <>
            <StationSearchPageTitle
                title={intl.formatMessage({ id: 'pages.schoolbusStationSearchByArea.title' })}
                headTitle={intl.formatMessage({ id: 'pages.schoolbusStationSearchByArea.htmlHead.title' })}
            />

            {rids && usemap &&
                <MapView>
                    {sid ?
                        (
                            <div className="schoolbus-station-schedule-map-view-header">
                                <Link to={`/schoolbus_station_schedule${location.search.replace(/(&usemap=\d*)|(&routes=\d*)/g, "")}`} className="back-button">
                                    <div className={`title-badge`}>
                                        <IconArrowLeftVariant3 />
                                    </div>
                                    <div className={`title-text`}>
                                        {intl.formatMessage({
                                            id: `pages.schoolbusStationSchedule.mapView.getBackToSchedule`,
                                        })}
                                    </div>
                                </Link>
                            </div>
                        )
                        : null
                    }
                    {aid && schid && rid ?
                        (
                            <div className="schoolbus-station-schedule-map-view-header">
                                <Link to={`/schoolbus_station_search_by_area${location.search.replace(/(&usemap=\d*)|(&routes=\d*)/g, "")}`} className="back-button">
                                    <div className={`title-badge`}>
                                        <IconArrowLeftVariant3 />
                                    </div>
                                    <div className={`title-text`}>
                                        {intl.formatMessage({
                                            id: `pages.schoolbusStationSchedule.mapView.getBackToSearch`,
                                        })}
                                    </div>
                                </Link>
                            </div>
                        )
                        : null
                    }
                </MapView>
            }

            {!(rids && usemap) &&
                <>

                    {loading && (
                        <LoadingScreen message={intl.formatMessage({ id: 'pages.schoolbusStationSearchByArea.LoadingScreen' })} />
                    )}


                    {!loading && (
                        <>
                            {aid && areasReady && (
                                <div className="stationSearchByTypePage-subTitleWrapper">
                                    <Link to={`${routeMatch.path}?aid=`} className="back-button">
                                        <IconBack />
                                    </Link>
                                    <Link to={`${routeMatch.path}?aid=`} className=" type-title">
                                        {
                                            transliterator().transliterate(areas?.find(item => item.id == aid)?.name) || intl.formatMessage({
                                                id: `pages.schoolbusStationSearchByArea.areas`,
                                            })
                                        }
                                    </Link>
                                </div>
                            )}
                            {aid && schid && schoolsReady && (
                                <div className="stationSearchByTypePage-subTitleWrapper">
                                    <Link to={`${routeMatch.path}?aid=${aid}`} className="back-button">
                                        <IconBack />
                                    </Link>
                                    <Link to={`${routeMatch.path}?aid=${aid}`} className="type-title">
                                        {
                                            transliterator().transliterate(schools?.find(item => item.id == schid)?.name) || intl.formatMessage({
                                                id: `pages.schoolbusStationSearchByArea.schools`,
                                            })
                                        }
                                    </Link>
                                </div>
                            )}
                            {aid && schid && rid && routesReady && (
                                <div className="stationSearchByTypePage-subTitleWrapper">
                                    <Link to={`${routeMatch.path}?aid=${aid}&schid=${schid}`} className="back-button">
                                        <IconBack />
                                    </Link>
                                    <Link to={`${routeMatch.path}?aid=${aid}&schid=${schid}`} className="type-title">
                                        {
                                            transliterator().transliterate(
                                                replaceRouteName(routes?.find(item => item.id == rid)?.name)
                                            )
                                            || intl.formatMessage({
                                                id: `pages.schoolbusStationSearchByArea.routes`,
                                            })
                                        }
                                    </Link>
                                </div>
                            )}

                            {!aid && !schid && !rid && (
                                <>
                                    {areasError && (
                                        <ErrorScreen
                                            message={
                                                areasTimeout ?
                                                    intl.formatMessage({
                                                        id: 'pages.schoolbusStationSearchByArea.timeoutScreen'
                                                    }, {
                                                        br: <br key={0} />,
                                                    })
                                                    : intl.formatMessage({
                                                        id: 'pages.schoolbusStationSearchByArea.ErrorScreen',
                                                    }, {
                                                        br: <br key={0} />,
                                                    })
                                            }
                                        />
                                    )}
                                    {!areasError && areasReady && <AreasList />}
                                </>
                            )}
                            {aid && !schid && !rid && (
                                <>
                                    {schoolsError && (
                                        <ErrorScreen
                                            message={
                                                schoolsTimeout ?
                                                    intl.formatMessage({
                                                        id: 'pages.schoolbusStationSearchByArea.timeoutScreen'
                                                    }, {
                                                        br: <br key={0} />,
                                                    })
                                                    : intl.formatMessage({
                                                        id: 'pages.schoolbusStationSearchByArea.ErrorScreen',
                                                    }, {
                                                        br: <br key={0} />,
                                                    })
                                            }
                                        />
                                    )}
                                    {!schoolsError && schoolsReady && <SchoolsList />}
                                </>
                            )}
                            {aid && schid && !rid && (
                                <>
                                    {routesError && (
                                        <ErrorScreen
                                            message={
                                                routesTimeout ?
                                                    intl.formatMessage({
                                                        id: 'pages.schoolbusStationSearchByArea.timeoutScreen'
                                                    }, {
                                                        br: <br key={0} />,
                                                    })
                                                    : intl.formatMessage({
                                                        id: 'pages.schoolbusStationSearchByArea.ErrorScreen',
                                                    }, {
                                                        br: <br key={0} />,
                                                    })
                                            }
                                        />
                                    )}
                                    {!routesError && routesReady && <RoutesList />}
                                </>
                            )}
                            {aid && schid && rid && (
                                <>
                                    {stationsError && (
                                        <ErrorScreen
                                            message={
                                                stationsTimeout ?
                                                    intl.formatMessage({
                                                        id: 'pages.schoolbusStationSearchByArea.timeoutScreen'
                                                    }, {
                                                        br: <br key={0} />,
                                                    })
                                                    : intl.formatMessage({
                                                        id: 'pages.schoolbusStationSearchByArea.ErrorScreen',
                                                    }, {
                                                        br: <br key={0} />,
                                                    })
                                            }
                                        />
                                    )}
                                    {!stationsError && stationsReady && <StationsList />}
                                </>
                            )}

                        </>
                    )}
                </>
            }


        </>
    )
}

export default connect(
    state => ({
        areas: getAreas(state),
        schools: getSchools(state),
        routes: getRoutes(state),
        routesReady: getRoutesReady(state),
        schoolsReady: getSchoolsReady(state),
        areasReady: getAreasReady(state),
        stationsReady: getStationsReady(state),
        loading: getLoading(state),
        areasError: getAreasError(state),
        schoolsError: getSchoolsError(state),
        routesError: getRoutesError(state),
        stationsError: getStationsError(state),
        areasTimeout: getAreasTimeout(state),
        schoolsTimeout: getSchoolsTimeout(state),
        routesTimeout: getRoutesTimeout(state),
        stationTimeout: getStationsTimeout(state)
    }),
    {
        fetchAreas,
        fetchRoutes,
        fetchSchools,
        fetchStationsWithSchedule,
        clearStations,
        clearRoutes,
        clearSchools
    },
)(StationSearchPageByType)
