import axios from 'axios'
import config from '../resources/config'
const requestTimeout = config.common.backend.requestTimeout

const RouteNodesDataSource = () => {
    let nodes = []
    let nodesLoading = false
    let nodesError = false

    const load = async ({ routeId }) => {
        nodesError = false
        nodesLoading = true

        if (!routeId) {
            nodesLoading = false
            nodes = []
            console.warn('Не указан маршут для запроса узлов маршрутов')
            return []
        }

        let params = { id: routeId }

        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }

        const { data } = await axios
            .get('/getRouteNodes.php', {
                params,
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }

                return {
                    ...result,
                    data: result.data.map(item => {
                        return {
                            lat: parseInt(item.lat) / 1000000,
                            lon: parseInt(item.lon) / 1000000,
                        }
                    }),
                }
            })
            .catch(error => {
                console.log('Ошибка получения узлов маршрута: ' + error)
                return { data: [] }
            })

        nodesLoading = false
        nodes = data

        return data
    }

    const getLoadedData = () => {
        return nodes
    }

    const unloadData = () => {
        nodes = []
    }

    const getLoading = () => {
        return nodesLoading
    }

    const getError = () => {
        return nodesError
    }

    return { load, getLoadedData, unloadData, getLoading, getError }
}

export default RouteNodesDataSource
