import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
  color: PropTypes.string,
}

const Svg = ({ color }) => (
  <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" d="M4.43305 3.34535C4.75848 3.72112 4.75848 4.27888 4.43305 4.65465L2.25593 7.16858C1.64979 7.86849 0.499999 7.43981 0.499999 6.51393L0.499999 1.48607C0.499999 0.560187 1.64979 0.131516 2.25593 0.831419L4.43305 3.34535Z" fill="#485D6D" />
  </svg>

)

Svg.propTypes = propTypes

export default Svg
