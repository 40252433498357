import { transliterate as tr } from 'transliteration' //TODO: может сделать по типу provider или задавать локаль заранее как dayjs (переделав в класс).

const TRANSLITERATORS = {
    en: {
        transliterator: tr,
    },
}

let _locale = ''

const transliterator = () => {
    const transliterate = (text, locale) => {
        if (!locale && !_locale) {
            return text
        }

        if (!text) {
            return text
        }

        if (TRANSLITERATORS[locale || _locale]) {
            return TRANSLITERATORS[locale || _locale].transliterator(text)
        }

        return text
    }

    const setLocale = locale => {
        _locale = locale
    }

    //TODO: задавать транслитераторы и правила транслитерирования 
    //const setTransliterators = (transliterators) => {}
    //const updateTransliterators = (locale, {transliterator}) => {}

    //TODO: задавать игнорируемые локали 

    return { transliterate, setLocale }
}

export default transliterator
