import axios from 'axios'

import config from '../resources/config'

// axios.defaults.baseURL = config.common.backend.url

// axios.interceptors.request.use((axiosConfig) => {
//     axiosConfig.params = axiosConfig.params || {}

//     axiosConfig.params.city = config.common.backend.cityCode

//     return axiosConfig
// })

let userParams = {}

const setupAxios = (params = {}) => {
    axios.defaults.baseURL = config.common.backend.url
    userParams = { ...params }

    axios.interceptors.request.use(axiosConfig => {
        axiosConfig.params = axiosConfig.params || {}

        axiosConfig.params.city = config.common.backend.cityCode

        axiosConfig.params = { ...axiosConfig.params, ...userParams }
        return axiosConfig
    })
}

export { setupAxios }
