import React from 'react'
import { useIntl } from 'react-intl'
import { useGetQueryParams } from '../../services/useGetQuery'
import StationSearchPageTitle from '../../components/StationSearch/PageTitle'
import MapView from '../SchoolbusStationSearchByAreaPage/MapView'

const SchoolbusMap = () => {
    const intl = useIntl()
    const { routes: rids, usemap } = useGetQueryParams()

    return (
        <>
            <StationSearchPageTitle
                title={intl.formatMessage({ id: 'pages.schoolbusMap.title' })}
                headTitle={intl.formatMessage({ id: 'pages.schoolbusMap.htmlHead.title' })}
            />
            {rids && usemap && <MapView />}
        </>
    )
}

export default SchoolbusMap
