import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link, useLocation } from 'react-router-dom'

import dayjs from "dayjs"
import dayjs_relativeTime from "dayjs/plugin/relativeTime"
import { upperFirst } from "lodash-es"
import { useIntl } from "react-intl"

import translit from './../../services/transliterator'

import {
    getSchedules,
    getSchedulesLoading,
    getSchedulesReady,
    getSchedulesError,
    getSchedulesTimeout,
} from '../../store/schoolBusSchedulePageSlice'
import ScheduleFilters from "./ScheduleFilters"
import LoadingScreen from '../../components/StationSearch/LoadingScreen'
import ErrorScreen from '../../components/StationSearch/ErrorScreen'

import { IconSearchByType, IconGlobe, IconBus } from "../../resources/icons"

import "./ScheduleView.school.scss"


dayjs.extend(dayjs_relativeTime)

const ScheduleView = ({
    schedules,
    schedulesReady,
    schedulesLoading,
    schedulesError,
    schedulesTimeout
}) => {
    const intl = useIntl()


    return (
        <>
            <ScheduleFilters />
            {schedulesLoading && (
                <LoadingScreen
                    message={
                        intl.formatMessage({ id: 'pages.schoolbusStationSchedule.loading' })
                    }
                />
            )}

            {
                schedulesError ?
                    (
                        <ErrorScreen
                            message={intl.formatMessage(
                                schedulesTimeout ?
                                    { id: "default.timeoutScreen" }
                                    : { id: 'pages.schoolbusStationSchedule.error' }
                                , {
                                    br: <br key={0} />,
                                }
                            )}
                        />
                    )
                    :
                    schedulesReady && (
                        <div className="sfp-school-bus-schedule-view-wrapper">
                            {schedules.map((schedule, index) => {
                                return (
                                    <div className="schedule-route" key={index}>
                                        <div className="schedule-route-header"><span><IconSearchByType /></span><span>{translit().transliterate(schedule.routeName)}</span></div>
                                        <div className="schedule-items-container">
                                            {schedule?.scheduleTable?.length ?
                                                schedule?.scheduleTable?.map((scheduleItem, index) => {
                                                    return (<ScheduleItem key={index} scheduleItem={scheduleItem}
                                                        rids={[schedule.routeId]} />)
                                                })
                                                : (
                                                    <Link
                                                        to={`/schoolbus_station_search_by_area${
                                                            location.search
                                                        }&routes=${schedule.routeId}&usemap=1`}
                                                        className="map-link"
                                                    >
                                                        <IconGlobe />
                                                        <span>
                                                            {intl.formatMessage({
                                                                id: 'pages.schoolbusStationSchedule.scheduleView.onMap',
                                                            })}
                                                        </span>
                                                    </Link>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
            }

        </>
    )
}

const ScheduleItem = ({ scheduleItem, rids }) => {
    const intl = useIntl()
    const location = useLocation()

    return (

        <div className="schedule-item">
            <div className="forecast">
                <div className="arrival-time">{
                    scheduleItem.realForecastTime ?
                        `${Math.ceil(scheduleItem.realForecastTime / 60)} мин.`
                        :
                        upperFirst(
                            dayjs(scheduleItem.scheduleTime).format("HH:mm")
                        )
                }</div>
                {/* <a className="map-link" href="#"> */}
                <Link to={`/schoolbus_station_search_by_area${location.search}&routes=${rids.join(',')}&usemap=1`} className="map-link">
                    <IconGlobe />
                    <span>{
                        intl.formatMessage({ id: "pages.schoolbusStationSchedule.scheduleView.onMap" })
                    }</span>
                </Link>
                {/* </a> */}
            </div>
            <div className="vehicle">
                <div className="vehicleName">
                    <span className="icon-bus"><IconBus /></span>
                    <span>{translit().transliterate(scheduleItem.vehicleName || intl.formatMessage({ id: "pages.schoolbusStationSchedule.scheduleView.busNotAssigned" }))}</span>
                </div>
                <div className="driverName">{translit().transliterate(scheduleItem.driverFIO || intl.formatMessage({ id: "pages.schoolbusStationSchedule.scheduleView.driverNotAssigned" }))}</div>
            </div>
        </div>
    )
}

export default connect(
    (state) => ({
        schedules: getSchedules(state),
        schedulesReady: getSchedulesReady(state),
        schedulesLoading: getSchedulesLoading(state),
        schedulesError: getSchedulesError(state),
        schedulesTimeout: getSchedulesTimeout(state)
    }),
    ({
    })
)(ScheduleView)