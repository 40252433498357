import React from 'react'
import PropTypes from 'prop-types'

import './Buttons.school.scss'


const propTypes = {
    style: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

const PrimaryButton = ({ children, style, onClick }) => (
    <div className="primary-button" style={style} onClick={onClick} aria-hidden>
        {children}
    </div>
)

PrimaryButton.propTypes = propTypes

export default PrimaryButton
