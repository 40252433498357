import { deauthorize } from './commonSlice'
import { pinError } from './sharedActions'

export const loginMiddleware = store => {
    return next => action => {
        next(action)

        if (action.type === pinError.toString()) {
            store.dispatch(deauthorize())
        }
    }
}
