import React from 'react'
import { connect } from 'react-redux'
import isArray from 'lodash-es/isArray'

import { getTransportsIconsVisible, getTransportsNumberVisible, getLocale } from '../../../store/commonSlice'
import { getTransportColumnDescView, getVisibleForecasts } from '../../../store/stationForecastPageSlice'

import Header from './Header'
import ForecastRow from './ForecastRow'

const ROUTE_STATUS = {
    HAS_ARRTIME: 0,
    OPPOSITE_DIRECTION: 1,
    ON_FINAL: 2,
    NO_DATA: 3,
}

const nearArrTimeSort = (itemA, itemB) => {
    let nearArrtA = null
    let nearArrtB = null
    let statusA = 0
    let statusB = 0

    if (itemA.fores.length) {
        nearArrtA = itemA.fores[0].arrt
        statusA = nearArrtA !== Infinity ? ROUTE_STATUS.HAS_ARRTIME : ROUTE_STATUS.ON_FINAL
    } else {
        if (itemA.info === 'no_transports_in_our_direction') {
            statusA = ROUTE_STATUS.OPPOSITE_DIRECTION
        }
        if (itemA.info === 'no_transports_with_gps') {
            statusA = ROUTE_STATUS.NO_DATA
        }
    }

    if (itemB.fores.length) {
        nearArrtB = itemB.fores[0].arrt
        statusB = nearArrtB !== Infinity ? ROUTE_STATUS.HAS_ARRTIME : ROUTE_STATUS.ON_FINAL
    } else {
        if (itemB.info === 'no_transports_in_our_direction') {
            statusB = ROUTE_STATUS.OPPOSITE_DIRECTION
        }
        if (itemB.info === 'no_transports_with_gps') {
            statusB = ROUTE_STATUS.NO_DATA
        }
    }

    if (statusA === ROUTE_STATUS.HAS_ARRTIME && statusB === ROUTE_STATUS.HAS_ARRTIME) {
        return nearArrtA - nearArrtB
    }
    return statusA - statusB
}

/* eslint react/prop-types: ["off"] */
const Grid = ({ transportsIconsVisible, transportsNumberVisible, transportColumnDescView, visibleForecasts, locale }) => (
    <>
        <Header />
        {isArray(visibleForecasts)
            && visibleForecasts.length > 0
            && visibleForecasts
                .sort(nearArrTimeSort)
                .map(routeForecasts => (
                    <ForecastRow
                        key={routeForecasts.rid}
                        transportsIconsVisible={transportsIconsVisible}
                        transportsNumberVisible={transportsNumberVisible}
                        transportColumnDescView={transportColumnDescView}
                        routeForecasts={routeForecasts}
                    />
                ))}
    </>
)

export default connect(state => ({
    transportsIconsVisible: getTransportsIconsVisible(state),
    transportsNumberVisible: getTransportsNumberVisible(state),
    transportColumnDescView: getTransportColumnDescView(state),
    visibleForecasts: getVisibleForecasts(state),
    locale: getLocale(state)
}))(Grid)
