import React from 'react'
import PropTypes from 'prop-types'

import IconSegments from './IconSegments'
import IconType from './IconType'

const propTypes = {
    iconType: PropTypes.oneOf(['volume', 'not-volume', 'place']).isRequired,
    routeTypes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            short_name: PropTypes.string.isRequired,
        }),
    ).isRequired,
}

const IconStation = ({ iconType, routeTypes }) => {
    routeTypes = routeTypes.map(routeType => routeType.short_name)

    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
            className="icon-station"
        >
            <rect x="1" y="1" width="46" height="46" rx="7" fill="white" className="icon-station-outer-background" />

            <IconSegments routeTypes={routeTypes} />

            <rect x="9" y="9" width="30" height="30" rx="15" fill="white" />

            <IconType iconType={iconType} />
        </svg>
    )
}

IconStation.propTypes = propTypes

export default IconStation
