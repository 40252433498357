import Leaflet, { map } from 'leaflet'
import { cloneDeep } from 'lodash-es'
import * as dayjs from 'dayjs'
import * as dayjs_isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(dayjs_isSameOrBefore)

//TODO: оценить насколько хорошее решние создавать новые слои. Оценить вомзможность и целесообразность конфигурировать слои через опции

const VehicleLayerComponent = ({ config, dataSource, componentOptions }) => {
    let markerLayer = null

    let vehicleCache = {}
    let textureCache = {}

    let highestAnimKey = 0

    //let isTexturesLoaded = false
    let isTexturesLoaded = true
    let isUpdatingSeamlessly = false

    let onMarkerClickCallback = null

    const vehiclesDataSource = dataSource()

    const animationFrameCount = config ? Math.trunc(config.animationDurationMS / config.animationFrameTimeMS) : 1

    // function getLines(ctx, text, maxWidth) {
    //     let words = maxWidth ? text.split(' ') : text.split('\n')
    //     let lines = []
    //     let currentLine = words[0]
    //     for (let i = 1; i < words.length; i++) {
    //         let word = words[i]
    //         let width = ctx.measureText(currentLine + ' ' + word).width
    //         if (maxWidth) {
    //             if (width < maxWidth) {
    //                 currentLine += ' ' + word
    //             } else {
    //                 lines.push(currentLine)
    //                 currentLine = word
    //             }
    //         } else {
    //             lines.push(word)
    //         }
    //     }
    //     lines.push(currentLine)
    //     return lines
    // }

    // function canvasMarkerUpdatePath() {
    //     if (!this.options.imageCache) {
    //         this.options.imageCache = {}
    //     }
    //     if (!this.options.img.el || !this.options.img2.el) {
    //         //TODO:работа с N изобажений
    //         //Создаем элемент IMG
    //         if (this.options.imageCache[this.options.img.url]) {
    //             this.options.img.el = this.options.imageCache[this.options.img.url]
    //             this.redraw()
    //         } else {
    //             const img = new Image()
    //             img.src = this.options.img.data
    //             this.options.img.el = img
    //             img.onload = () => {
    //                 this.options.imageCache[this.options.img.url] = img // это будет "img.el"
    //                 this.redraw() //После загрузки запускаем перерисовку
    //             }
    //         }
    //         if (this.options.imageCache[this.options.img2.url]) {
    //             this.options.img2.el = this.options.imageCache[this.options.img2.url]
    //             this.redraw() //TODO: наверно нужно только один раз
    //         } else {
    //             const img = new Image()
    //             img.src = this.options.img2.data
    //             this.options.img2.el = img
    //             img.onload = () => {
    //                 this.options.imageCache[this.options.img2.url] = img // это будет "img.el"
    //                 this.redraw() //После загрузки запускаем перерисовку
    //             }
    //         }
    //     } else {
    //         this._renderer._updateCanvasMarker(this) //Вызываем обновление маркера
    //     }
    // }

    // function canvasMarkerUpdate(layer) {
    //     const { img, text, img2 } = layer.options //TODO: может конифгурацию с задание порядка отрисовки?
    //     const p = layer._point.round()
    //     //console.log(this,layer)
    //     const canvasWidth = this._ctx.canvas.clientWidth
    //     const canvasHeight = this._ctx.canvas.clientHeight
    //     //debugger
    //     if (img.rotate) {
    //         this._ctx.save()
    //         this._ctx.translate(p.x, p.y)
    //         this._ctx.rotate((img.rotate * Math.PI) / 180)
    //         this._ctx.drawImage(img.el, -img.size[0] / 2, -img.size[1] / 2, img.size[0], img.size[1])
    //         this._ctx.restore()
    //     } else {
    //         this._ctx.drawImage(img.el, p.x - img.size[0] / 2, p.y - img.size[1] / 2, img.size[0], img.size[1])
    //     }
    //     if (text.text) {
    //         let textHeight = 20
    //         let textPadding = 8
    //         let markerWidth = 32
    //         let maxWidth = componentOptions?.markerOptions?.maxLabelWidth
    //         if (componentOptions?.markerOptions?.textHeight) {
    //             textHeight = componentOptions.markerOptions.textHeight
    //         }
    //         if (componentOptions?.markerOptions?.textPadding) {
    //             textPadding = componentOptions.markerOptions.textPadding
    //         }

    //         if (componentOptions?.markerOptions?.type === 2) {
    //             let lines = getLines(this._ctx, text.text, maxWidth)

    //             let lineWidth = 0

    //             lines.forEach(item => {
    //                 const itemWidth = this._ctx.measureText(item).width
    //                 if (itemWidth > lineWidth) {
    //                     lineWidth = itemWidth
    //                 }
    //             })

    //             this._ctx.fillStyle = 'white'
    //             this._ctx.fillRect(
    //                 p.x + text.x - textPadding + markerWidth,
    //                 p.y + text.y - textHeight / 2 - textPadding,
    //                 lineWidth + textPadding * 2,
    //                 textHeight * lines.length + textPadding * 2,
    //             )
    //             // this._ctx.fillRect(
    //             //     p.x + text.x - lineWidth / 2 - textPadding,
    //             //     p.y + text.y - textHeight / 2 - textPadding,
    //             //     lineWidth + textPadding * 2,
    //             //     textHeight * lines.length + textPadding * 2,
    //             // )

    //             this._ctx.font = `${textHeight}px Arial`
    //             this._ctx.fillStyle = 'black'
    //             // this._ctx.textAlign = 'center'
    //             this._ctx.textAlign = 'left'
    //             this._ctx.textBaseline = 'middle'

    //             lines.forEach((line, index) => {
    //                 this._ctx.fillText(line, p.x + text.x + markerWidth, p.y + text.y + index * textHeight)
    //             })
    //             // lines.forEach((line, index) => {
    //             //     this._ctx.fillText(line, p.x + text.x, p.y + text.y + index * textHeight)
    //             // })
    //         } else {
    //             this._ctx.font = `${textHeight}px Arial`
    //             this._ctx.fillStyle = 'black'
    //             this._ctx.textAlign = 'center'
    //             this._ctx.textBaseline = 'middle'
    //             this._ctx.fillText(text.text, p.x + text.x, p.y + text.y)
    //         }
    //     }

    //     if (img2) {
    //         this._ctx.drawImage(img2.el, p.x - img2.size[0] / 2, p.y - img2.size[1] / 2, img2.size[0], img2.size[1])
    //     }
    // }

    // const CanvasMarker = Leaflet.CircleMarker.extend({
    //     _updatePath() {
    //         canvasMarkerUpdatePath.call(this)
    //     },
    // })

    // Leaflet.canvasMarker = function (...options) {
    //     return new CanvasMarker(...options)
    // }

    // Leaflet.Canvas.include({
    //     _updateCanvasMarker(layer) {
    //         canvasMarkerUpdate.call(this, layer)
    //     },
    // })

    const loadTextures = async ({ texturesSrc = [] }) => {
        return

        if (texturesSrc) {
            const promiseList = []
            isTexturesLoaded = false
            texturesSrc.forEach(textureSrc => {
                promiseList.push(
                    import(`./../../resources/images/transport-icons/${textureSrc.url}`)
                        // вебпак не разрешает полностью динамический путь, т.е. с кучей подпапок в динамической части. Придётся делать так либо грузить по другому.
                        .then(result => Promise.resolve({ data: result.default, name: textureSrc.name })),
                )
            })
            await Promise.allSettled(promiseList).then(result => {
                result.forEach(item => {
                    if (item.status === 'fulfilled') {
                        textureCache[item.value.name] = item.value.data
                    } else if (true) {
                        console.error(item.reason)
                    }
                })
                isTexturesLoaded = true
            })
            return Promise.resolve()
        } else {
            return Promise.reject()
        }
    }

    const loadVehicles = async ({ filters }) => {
        return await vehiclesDataSource.load({
            filters: filters,
            currentAnimKey: highestAnimKey,
        })
    }

    const createLayer = ({ mapRef }) => {
        markerLayer = Leaflet.layerGroup([])
        markerLayer.addTo(mapRef)
        // mapRef.createPane('markerPaneTop')
        // mapRef.getPane('markerPaneTop').style.zIndex = 601
    }

    const removeLayer = () => {
        markerLayer?.remove()
        markerLayer = null
    }

    const animateFrame = ({ isZooming, MARKER_RTYPE_TO_TEXTURE, isWindowFocusedRef = true }) => {
        if (isUpdatingSeamlessly) {
            return
        }

        const newVehicleMarkersCache = {}
        for (const key of Object.keys(vehicleCache)) {
            newVehicleMarkersCache[key] = vehicleCache[key]
            animateVehicleAnimationFrame({ marker: newVehicleMarkersCache[key] })
        }
        vehicleCache = newVehicleMarkersCache
        if (isWindowFocusedRef) {
            updateMapMarkers({
                isZooming: isZooming,
                MARKER_RTYPE_TO_TEXTURE: MARKER_RTYPE_TO_TEXTURE,
            })
        }
    }

    const updateVehicles = async ({ isZooming, filters, MARKER_RTYPE_TO_TEXTURE }) => {
        let positions = await loadVehicles({
            filters: filters,
        })
        updateVehicleMarkersCache({
            positions: positions,
        })
        updateMapMarkers({
            isZooming: isZooming,
            MARKER_RTYPE_TO_TEXTURE: MARKER_RTYPE_TO_TEXTURE,
        })
    }

    const updateVehicleMarkersCache = ({ positions, seamlessly = false }) => {
        isUpdatingSeamlessly = seamlessly

        const newVehicleMarkersCache = {}
        for (let index = 0; index < positions.length; index += 1) {
            const position = positions[index]
            const cachedMarker = vehicleCache[position.id]
            if (!cachedMarker) {
                const newMarker = createVehicleMarker({ position: position })
                initializeVehicleAnimation({ marker: newMarker, position })
                newVehicleMarkersCache[position.id] = newMarker
            } else {
                const newMarker = cloneDeep(cachedMarker) //?
                updateVehicleMarker({ marker: newMarker, position })
                animateVehicleAnimationFrame({ marker: newMarker })
                newVehicleMarkersCache[position.id] = newMarker
            }
            if (newVehicleMarkersCache[position.id].animKey > highestAnimKey) {
                highestAnimKey = newVehicleMarkersCache[position.id].animKey
            }
        }

        for (const key of Object.keys(vehicleCache)) {
            if (!newVehicleMarkersCache[key]) {
                if (!dayjs(vehicleCache[key].lastUpdate).isSameOrBefore(dayjs().subtract(3, 'minutes'))) {
                    newVehicleMarkersCache[key] = vehicleCache[key]
                    if (newVehicleMarkersCache[key].animKey > highestAnimKey) {
                        highestAnimKey = newVehicleMarkersCache[key].animKey
                    }
                }
            }
        }

        vehicleCache = newVehicleMarkersCache
        isUpdatingSeamlessly = false
    }

    const createVehicleMarker = ({ position }) => {
        const marker = {
            id: position.id,
            lat: position.lat,
            lng: position.lng,
            direction: position.dir + 90,
            routeType: position.rtype,
            routeNumber: position.rnum,
            routeId: position.rid,
            gosNum: position.gos_num,
            animKey: position.anim_key,
            animationPoints: position.anim_points.map(parseAnimationPoint),
            currentLat: position.lat,
            currentLng: position.lng,
            currentDirection: position.dir,
            currentFrame: 0,
            currentDirectionFrame: 0,
            deltaLat: 0,
            deltaLng: 0,
            deltaDirection: 0,
            lastUpdate: new Date(),
            flags: position.flags,
            bigJump: position.big_jump,
            wifi: position.wifi,
            lowFloor: position.low_floor,
        }

        return marker
    }

    const parseAnimationPoint = animationPoint => {
        return {
            direction: parseInt(animationPoint.dir, 10) + 90,
            percent: parseInt(animationPoint.percent, 10),
            lat: parseInt(animationPoint.lat, 10) / 1000000,
            lng: parseInt(animationPoint.lon, 10) / 1000000,
            index: animationPoint.index,
        }
    }

    const initializeVehicleAnimation = ({ marker, position }) => {
        if (marker.animationPoints.length > 0) {
            const animationPoint = { ...marker.animationPoints[0] }
            marker.animationPoints.shift()
            if (animationPoint === undefined) {
                return
            }
            marker.currentLat = animationPoint.lat
            marker.currentLng = animationPoint.lng
            marker.currentDirection = animationPoint.direction
            moveVehicleToNextPoint({ marker })
        } else {
            marker.currentLat = position.lat
            marker.currentLng = position.lng
            marker.currentDirection = position.dir
        }
    }

    const animateVehicleAnimationFrame = ({ marker }) => {
        if (marker.currentFrame > 0) {
            //если кадры анимации ещё есть, то двигаем транспорт
            marker.currentFrame -= 1
            marker.currentLat += marker.deltaLat
            marker.currentLng += marker.deltaLng

            if (marker.currentDirectionFrame) {
                marker.currentDirectionFrame -= 1
            }
            if (marker.currentDirectionFrame > 0) {
                marker.currentDirection += marker.deltaDirection
            }
        } else {
            moveVehicleToNextPoint({ marker })
        }
    }

    const moveVehicleToNextPoint = ({ marker }) => {
        if (!marker.animationPoints.length) {
            return
        }
        if (marker.currentFrame > 0) {
            //анимация уж идёт
            return
        }

        //если кадров анимации нет и точки анимации не закончились, то берём актуальную точку анимации как новую начальную

        const targetAnimationPoint = { ...marker.animationPoints[0] }
        marker.animationPoints.shift() // использованную точку анимации удаляем

        const animationFramesInSegment = Math.trunc((animationFrameCount * targetAnimationPoint.percent) / 100)
        const angleAnimationFramesInSegment = Math.trunc(
            (animationFrameCount * targetAnimationPoint.percent) / (100 * 10),
        )

        marker.deltaLat = (targetAnimationPoint.lat - marker.currentLat) / animationFramesInSegment
        marker.deltaLng = (targetAnimationPoint.lng - marker.currentLng) / animationFramesInSegment
        marker.deltaDirection =
            getShortestAngle({
                targetAngle: targetAnimationPoint.direction,
                currentAngle: marker.currentDirection,
            }) / angleAnimationFramesInSegment

        marker.currentFrame = animationFramesInSegment
        marker.currentDirectionFrame = angleAnimationFramesInSegment
    }

    const updateVehicleMarker = ({ marker, position }) => {
        marker.routeType = position.rtype
        marker.routeNumber = position.rnum
        marker.routeId = position.rid

        if (!marker.animationPoints) {
            marker.animationPoints = []
        }

        if (marker.animKey !== position.anim_key) {
            if (position.anim_points.length) {
                marker.animationPoints = marker.animationPoints.concat(position.anim_points.map(parseAnimationPoint))
            }
        }

        marker.animKey = position.anim_key

        if (marker.bigJump) {
            // TODO:  возможно надо делать раньше
            marker.animationPoints = []
            marker.currentLat = position.lat
            marker.currentLng = position.lng
            marker.currentDirection = position.dir
        }
    }

    //TODO: переименовать в более очевидное "drawMapMarkers" for excample
    //TODO: А так-же рефакторинг что-бы сделать это всё модульнее
    const updateMapMarkers = ({ isZooming, MARKER_RTYPE_TO_TEXTURE }) => {
        if (markerLayer && isTexturesLoaded && !isZooming) {
            //TODO: я думаю, что алгоритм можно сделать лучше и может даже быстрее. Возможно нужно.
            const keysUpdated = {}
            const layersToDelete = []
            const markerLayerKeys = Object.keys(markerLayer._layers)

            for (let index = 0; index < markerLayerKeys.length; index++) {
                let layerInCache = vehicleCache[markerLayer._layers[markerLayerKeys[index]]?.options?.meta?.id]

                if (layerInCache) {
                    //markerLayer._layers[markerLayerKeys[index]].options.img.rotate = layerInCache.currentDirection

                    markerLayer._layers[markerLayerKeys[index]].setLatLng([
                        layerInCache.currentLat,
                        layerInCache.currentLng,
                    ])

                    if (
                        markerLayer._layers[markerLayerKeys[index]].options.meta.direction !==
                        layerInCache.currentDirection
                    ) {
                        markerLayer._layers[markerLayerKeys[index]].setIcon(
                            createBusIcon({
                                direction: layerInCache.currentDirection,
                                gosNum: markerLayer._layers[markerLayerKeys[index]].options.meta.gosNum,
                            }),
                        )
                        markerLayer._layers[markerLayerKeys[index]].options.meta.direction =
                            layerInCache.currentDirection
                    } // может не проскакивать событие, если менять иконку слишком часто. Изменять иконку без доработок иначе как через setIcon или изменяя непосредственно div не получиться.

                    keysUpdated[layerInCache.id] = layerInCache.id
                } else {
                    layersToDelete.push(markerLayer._layers[markerLayerKeys[index]]._leaflet_id)
                }
            }

            for (let index = 0; index < layersToDelete.length; index++) {
                markerLayer.removeLayer(layersToDelete[index])
            }

            Object.keys(vehicleCache).forEach(key => {
                if (!keysUpdated[key]) {
                    const addedVehicle = vehicleCache[key]

                    // const textureNameDefault =
                    //     MARKER_RTYPE_TO_TEXTURE[Object.keys(MARKER_RTYPE_TO_TEXTURE)[0]].textureName
                    // const textureMapInfo = MARKER_RTYPE_TO_TEXTURE[addedVehicle.routeType]
                    // const textureNameImg = textureMapInfo ? textureMapInfo.textureName : textureNameDefault
                    // const textureImg = textureCache[textureNameImg] //TODO: flawed, do something
                    // const textureImg2 = textureCache['school_bus_1']

                    //TODO: проблемы. Надо подумать над сортировкой этого безобразия. Т.к. рендериться на целом canvas все иконки. Может расширить leaflet.canvas. Он не пропускает события на слои ниже. Нужны будут выкрутасы.
                    // const marker = Leaflet.canvasMarker([addedVehicle.currentLat, addedVehicle.currentLng], {
                    //     renderer: Leaflet.canvas({pane: "markerPaneTop"}),
                    //     text: {
                    //         // text: addedVehicle.routeNumber,
                    //         x: 0,
                    //         y: 0,
                    //     },
                    //     img: {
                    //         data: textureImg,
                    //         size: [68, 68],
                    //         rotate: addedVehicle.currentDirection,
                    //     },
                    //     img2: {
                    //         data: textureImg2,
                    //         size: [22, 22],
                    //     },
                    //     meta: { id: addedVehicle.id },
                    //     radius: 30, // Может не быть постоянным решением проблемы шлейфов. Но суть что он clearrect делает внутренне перед обновлением, а размер берёт здесь.
                    // }).on(
                    //     'click',
                    //     onMarkerClickCallback?.bind(
                    //         null,
                    //         addedVehicle.id,
                    //         addedVehicle.routeType,
                    //         addedVehicle.routeNumber,
                    //         addedVehicle.direction,
                    //         addedVehicle.position,
                    //     ),
                    // )

                    const marker = Leaflet.marker([addedVehicle.currentLat, addedVehicle.currentLng], {
                        icon: createBusIcon({ direction: addedVehicle.currentDirection, gosNum: addedVehicle.gosNum }),
                        meta: {
                            id: addedVehicle.id,
                            direction: addedVehicle.currentDirection,
                            gosNum: addedVehicle.gosNum,
                        },
                    })

                    markerLayer.addLayer(marker)
                    marker.on(
                        'click',
                        onMarkerClickCallback?.bind(
                            null,
                            addedVehicle.id,
                            addedVehicle.routeType,
                            addedVehicle.routeNumber,
                            addedVehicle.direction,
                            addedVehicle.position,
                        ),
                    )
                }
            })
        }
    }

    function createBusIcon({ direction, gosNum }) {
        return Leaflet.divIcon({
            className: 'transport-marker',
            html: createBusIconHTML({ direction, gosNum }),
            iconAnchor: [34, 34],
            iconSize: [68, 68],
        })
    }

    function createBusIconHTML({ direction, gosNum }) {
        return `
                    <div class="transport-marker_body">
                        <div class="transport-marker_icon-background school-bus"
                            style="transform: rotate(${direction}deg)"
                        ><div class="school-bus_message" style="transform: rotate(
                                ${360-direction}deg
                            ) ">${gosNum}</div></div>
                        <div class="transport-marker_icon-foreground school-bus"></div
                    </div>
                `
    }

    const getShortestAngle = ({ targetAngle, currentAngle }) => {
        let shortestAngle = targetAngle - currentAngle
        if (shortestAngle > 180) {
            shortestAngle -= 360
        }
        if (shortestAngle < -180) {
            shortestAngle += 360
        }

        return shortestAngle
    }

    const clearVehicleCache = () => {
        vehicleCache = {}
    }

    const resetAnimKey = () => {
        highestAnimKey = 0
    }

    const setCallback = callback => {
        onMarkerClickCallback = callback
    }

    return {
        createLayer,
        removeLayer,
        updateVehicles,
        animateFrame,
        loadTextures,
        clearVehicleCache,
        resetAnimKey,
        setCallback,
    }
}

export default VehicleLayerComponent
