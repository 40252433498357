import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
  color: PropTypes.string,
}

const Svg = ({ color }) => (
  <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" d="M0.566946 3.34535C0.241518 3.72112 0.241519 4.27888 0.566947 4.65465L2.74407 7.16858C3.35021 7.86849 4.5 7.43981 4.5 6.51393L4.5 1.48607C4.5 0.560187 3.35021 0.131516 2.74407 0.831419L0.566946 3.34535Z" fill="#485D6D" />
  </svg>
)

Svg.propTypes = propTypes

export default Svg
