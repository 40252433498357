import axios from 'axios'
import config from '../resources/config'

const requestTimeout = config.common.backend.requestTimeout

const VehiclesDataSource = () => {
    let positions = []

    const load = async ({ filters, currentAnimKey }) => {
        const params = { rids: null, curk: currentAnimKey }

        if (filters?.rids && Array.isArray(filters?.rids)) {
            params.rids = filters.rids.join(',')
        }

        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }

        const { data } = await axios
            .get('/getVehicleAnimationsNoProtect.php', {
                params,
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            })
            .catch(error => {
                console.log(error)
                return { data: [] }
            })

        //const {data} = await import("./../store/positions-test")

        if (!Array.isArray(data)) {
            positions = []
            return positions
        }
        // if (data.error) {
        //     return [] //TODO: добавить обработку ошибок как везде
        // }

        positions = data.map(item => ({
            id: '' + item.id,
            lat: +item.lat / 1000000,
            lng: +item.lng / 1000000,
            dir: +item.dir,
            rtype: item.rtype,
            rnum: item.rnum,
            gos_num: item.gos_num,
            rid: item.rid,
            anim_key: item.anim_key,
            big_jump: item.big_jump,
            anim_points: item.anim_points,
            wifi: item.wifi,
            low_floor: item.low_floor,
            flags: item.flags,
        }))

        return positions
    }

    const getLoadedData = () => {
        return positions
    }

    const unloadData = () => {
        positions = []
    }

    return { load, getLoadedData, unloadData }
}

export default VehiclesDataSource

