import React from 'react'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useGetQueryParams } from '../../services/useGetQuery'
import dayjs from "dayjs"

import translit from "./../../services/transliterator"

import { getStations } from '../../store/schoolbusStationSearchByAreaPageSlice'
import { IconArrowForward, IconGlobe } from '../../resources/icons'

import './TypeSelector.school.scss'


/* eslint react/prop-types: ["off"] */
const TypeSelector = ({ stations }) => {
    const intl = useIntl()
    const routeMatch = useRouteMatch()
    const { rid } = useGetQueryParams()
    const location = useLocation()

    return (
        <>
            <div className="ssbtp-type-selector-title">
                <span>{intl.formatMessage({
                    id: 'pages.schoolbusStationSearchByArea.stationsList.title',
                })
                }</span>
                <Link to={`/schoolbus_station_search_by_area${location.search}&routes=${rid}&usemap=1`} className="map-link">
                    <IconGlobe />
                    <span>{
                        intl.formatMessage({ id: "pages.schoolbusStationSchedule.scheduleView.onMap" })
                    }</span>
                </Link>
            </div>
            <div className="ssbtp-route-list-content">
                {stations.map((station, index) => (
                    <Link
                        key={station.ord}
                        to={`/schoolbus_station_schedule?sid=${station.station_id}&date=${dayjs(new Date()).format("YYYY-MM-DD")}`}
                        className="link-item list-item  "
                    >
                        <div
                            className={`list-item-badge-wrapper plainGray ${(index === 0) ? "first " : ""}
                            ${(index === stations.length - 1) ? "last " : ""}`
                            }
                        >
                            <div className="list-item-badge-wrapper2">
                                <div className="list-iItem-badge" />
                            </div>
                        </div>
                        <div className="list-item-link">
                            <div className="list-item-station-name">{
                                translit().transliterate(
                                    `${station.station_name}${station.station_description?.trim() ? ", " + station.station_description?.trim() : ""}`
                                )
                            }</div>
                            <div className="list-item-item-last-in-row-wrapper">

                                <div className="list-item-station-schedules">
                                    {station.scheduleTimes.map(item=>item.replace(/:\d\d$/, "")).join(", ")}
                                </div>

                                <div className="icon-arrow-forward-wrapper">

                                    <IconArrowForward />
                                </div>
                            </div>

                        </div>
                    </Link>
                ))}
            </div>

        </>
    )
}

export default connect(state => ({
    stations: getStations(state),
}))(TypeSelector)
