import { createAction } from '@reduxjs/toolkit'

const PIN_ERROR = 'shared/pin_error'

const pinError = createAction(PIN_ERROR, errorMessage => {
    return {
        payload: {
            errorMessage: errorMessage,
        },
    }
})

export { pinError }
