import axios from 'axios'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import { localStorageGetItem, localStorageSetItem } from '../services/localStorage'
import { arrayDiff } from '../services/utils'
import { pinError } from './sharedActions'

import config from '../resources/config'

const requestTimeout = config.common.backend.requestTimeout

const FAVORITES_LOCAL_STORAGE_KEY = 'BUS_FAVORITES'

export const FAVORITES_PAGE_REDUCER_KEY = 'favoritesPage'

const favoritesPageSlice = createSlice({
    name: FAVORITES_PAGE_REDUCER_KEY,
    initialState: {
        list: [],

        stations: {},
        stationsError: false,
        stationsTimeout: false,
    },
    reducers: {
        setFavorites: (state, { payload }) => {
            state.list = payload
        },
        addToFavorites: (state, { payload: stationId }) => {
            if (state.list.indexOf(stationId) === -1) {
                state.list.push(stationId)
            }
        },
        removeFromFavorites: (state, { payload: stationId }) => {
            const index = state.list.indexOf(stationId)
            if (index !== -1) {
                state.list = [...state.list.slice(0, index), ...state.list.slice(index + 1)]
            }
        },
        addToStations: (state, { payload: stations }) => {
            stations.forEach(station => {
                state.stations[station.id] = station
            })
            state.stationsTimeout = false
        },
        removeFromStations: (state, { payload: id }) => {
            delete state.stations[id]
        },
        setStationErrors: (state, { payload }) => {
            state.stationsError = payload
        },
        setStationsTimeout: state => {
            state.stationsTimeout = true
        },
        clearStationsTimeout: state => {
            state.stationsTimeout = false
        },
    },
})

export const getFavoritesPage = state => state.pages[FAVORITES_PAGE_REDUCER_KEY]
export const getFavorites = state => getFavoritesPage(state).list
export const getFavoritesStations = state => getFavoritesPage(state).stations
export const getHasStationsError = state => getFavoritesPage(state).stationsError
export const getFavoritesStationsList = createSelector([getFavorites, getFavoritesStations], (favorites, stations) => {
    const result = []

    favorites.forEach(id => {
        if (typeof stations[id] !== 'undefined') {
            result.push(stations[id])
        }
    })

    return result
})
export const getStationsTimeout = state => getFavoritesPage(state).stationsTimeout

export const addToFavorites = stationId => (dispatch, getState) => {
    dispatch(favoritesPageSlice.actions.addToFavorites(+stationId))
    const favorites = getFavorites(getState())
    localStorageSetItem(FAVORITES_LOCAL_STORAGE_KEY, favorites)
}

export const removeFromFavorites = stationId => (dispatch, getState) => {
    dispatch(favoritesPageSlice.actions.removeFromFavorites(stationId))
    dispatch(favoritesPageSlice.actions.removeFromStations(stationId))

    const favorites = getFavorites(getState())
    localStorageSetItem(FAVORITES_LOCAL_STORAGE_KEY, favorites)
}

export const initFavorites = stationId => dispatch => {
    const favorites = localStorageGetItem(FAVORITES_LOCAL_STORAGE_KEY)
    if (favorites && Array.isArray(favorites)) {
        dispatch(favoritesPageSlice.actions.setFavorites(favorites))
    }
}

export const loadFavoritesStations = () => async (dispatch, getState) => {
    dispatch(favoritesPageSlice.actions.setStationErrors(false))
    dispatch(favoritesPageSlice.actions.clearStationsTimeout())

    const state = getState()
    const favorites = getFavorites(state)
    const stations = getFavoritesStations(state)

    const needToLoad = arrayDiff(
        favorites,
        Object.keys(stations).map(item => +item),
    )

    if (needToLoad.length) {
        try {
            const cancelationToken = axios.CancelToken.source()

            let timeout = null
            if (requestTimeout) {
                timeout = setTimeout(() => {
                    cancelationToken.cancel('request timeout')
                }, requestTimeout)
            }

            const { data } = await axios
                .get('/getStationInfo.php', {
                    params: { sids: needToLoad.join(',') },
                    cancelToken: cancelationToken.token,
                })
                .then(result => {
                    if (timeout) {
                        clearTimeout(timeout)
                    }
                    return result
                })

            if (data.error) {
                dispatch(pinError(data.error))
                throw new Error('Неверный пин')
            }

            data.forEach((item, i) => {
                item.id = needToLoad[i]
            })

            dispatch(favoritesPageSlice.actions.addToStations(data))
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Ошибка получения данных об остановках', err)
            dispatch(favoritesPageSlice.actions.setStationErrors(true))
            if (err?.message === 'request timeout') {
                dispatch(favoritesPageSlice.actions.setStationsTimeout())
            }
        }
    }
}

export default favoritesPageSlice.reducer
