import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { reduxBatch } from '@manaflair/redux-batch'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import { history } from '../services/history'

import commonReducer, { COMMON_REDUCER_KEY } from './commonSlice'
import stationForecastPageReducer, {
    initExceptedRoutes,
    STATION_FORECAST_PAGE_REDUCER_KEY,
} from './stationForecastPageSlice'
import stationSearchByTypePageSlice, { STATION_SEARCH_BY_TYPE_PAGE_REDUCER_KEY } from './stationSearchByTypePageSlice'
import stationSearchByKeywordPageSlice, {
    STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY,
} from './stationSearchByKeywordPageSlice'
import stationSearchByLocationPageSlice, {
    STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY,
} from './stationSearchByLocationPageSlice'
import favoritesPageSlice, { FAVORITES_PAGE_REDUCER_KEY, initFavorites } from './favoritesPageSlice'
import historyPageSlice, { initHistory, HISTORY_PAGE_REDUCER_KEY } from './historyPageSlice'
import MapPageSlice, { MAP_PAGE_REDUCER_KEY } from './mapPageSlice'
import schoolBusSchedulePageSlice, { SCHOOL_BUS_SCHEDULE_PAGE_SLICE } from './schoolBusSchedulePageSlice'
import schoolbusStationSearchByAreaPageSlice, {
    SCHOOLBUS_STATION_SEARCH_BY_AREA_PAGE_REDUCER_KEY,
} from './schoolbusStationSearchByAreaPageSlice'

import { saveUserConfigMiddleware } from './saveUserConfigMiddleware'
import { stationForecastPageMiddleware } from './stationForecastPageMiddleware'
import { schoolBusSchedulePageMiddleware } from './schoolBusSchedulePageMiddleware'
import { loginMiddleware } from './loginMiddleware'
import scheduleSlice from './hasScheduleSlice'

//TODO: реорганизовать файловую структуру хранилища, что-бы это всё не лежало в такой куче

const rootReducer = combineReducers({
    router: connectRouter(history),
    [COMMON_REDUCER_KEY]: commonReducer,
    pages: combineReducers({
        [STATION_FORECAST_PAGE_REDUCER_KEY]: stationForecastPageReducer,
        [STATION_SEARCH_BY_TYPE_PAGE_REDUCER_KEY]: stationSearchByTypePageSlice,
        [STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY]: stationSearchByKeywordPageSlice,
        [STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY]: stationSearchByLocationPageSlice,
        [FAVORITES_PAGE_REDUCER_KEY]: favoritesPageSlice,
        [HISTORY_PAGE_REDUCER_KEY]: historyPageSlice,
        [MAP_PAGE_REDUCER_KEY]: MapPageSlice,
        [SCHOOL_BUS_SCHEDULE_PAGE_SLICE]: schoolBusSchedulePageSlice,
        [SCHOOLBUS_STATION_SEARCH_BY_AREA_PAGE_REDUCER_KEY]: schoolbusStationSearchByAreaPageSlice,
    }),
    hasSchedule: scheduleSlice
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: [
        ...getDefaultMiddleware(),
        routerMiddleware(history),
        saveUserConfigMiddleware,
        loginMiddleware,
        stationForecastPageMiddleware,
        schoolBusSchedulePageMiddleware,
    ],
    enhancers: [reduxBatch],
})

// Load favorites stations from LocalStorage
store.dispatch(initFavorites())
// Load history stations from LocalStorage
store.dispatch(initHistory())
// Load excepted routes from LocalStorage
store.dispatch(initExceptedRoutes())

export default store
