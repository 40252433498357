import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'

import { IconArrowForward } from '../../resources/icons'
import transliterator from '../../services/transliterator'
import {
    getTransportRoutesType,
    getGroupedRoutes,
    getTransportTypesHash,
} from '../../store/stationSearchByTypePageSlice'

import './RoutesList.school.scss'

/* eslint react/prop-types: ["off"] */
const RoutesList = ({ typeId, transportType, transportRoutesType, transportRoutes, transportTypesHash }) => {
    const intl = useIntl()
    const routeMatch = useRouteMatch()

    if (!transportRoutesType || !transportTypesHash || !Object.keys(transportTypesHash).length) {
        return null
    }

    return (
        <>
            <div className="ssbtp-routes-lists-subtitle">
                {intl.formatMessage({ id: 'pages.stationSearchByType.routesList.title' })}
            </div>
            <div className="ssbtp-routes-lists-list-wrapper">
                {Object.keys(transportRoutes).map(item => (
                    <div key={item} className="list-item">
                        <div className={`list-item-badge ${transportType}`}>{item}</div>
                        <div className="list-item-routes">
                            {transportRoutes[item].map(route => (
                                <Link
                                    key={route.id}
                                    to={`${routeMatch.path}?tid=${typeId}&rid=${route.id}`}
                                    className="list-item-route"
                                >
                                    <div className="list-item-route-name">
                                        {transliterator().transliterate(route.from_station_name)}
                                        &nbsp;-&nbsp;
                                        {transliterator().transliterate(route.to_station_name)}
                                    </div>
                                    <div className="icon-arrow-forward-wrapper">
                                        <IconArrowForward />
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default connect(state => ({
    transportRoutesType: getTransportRoutesType(state),
    transportRoutes: getGroupedRoutes(state),
    transportTypesHash: getTransportTypesHash(state),
}))(RoutesList)
