import React, { forwardRef, useImperativeHandle, useRef, useEffect } from "react"
import { isEmpty, isNumber, noop } from "lodash-es"

import "./pininput.scss"



const PinInput = ({ onChange, onEndInput, value }, ref) => {
  const inputsRef = useRef(new Array(value.length))
  const currentFocusIndexRef = useRef(null)

  useEffect(() => {
    if (inputsRef) {
      inputsRef.current[0]?.focus();
    }
  }, [])

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputsRef.current[0]?.focus();
    }
  }));

  const eventHandlerChange = (e) => {
    const index = parseInt(e.target.getAttribute("index"))
    const oldPositionValue = value[index]
    const newPositionValue = e.target.value.trim().replace(oldPositionValue, "")

    let newValue = [...value]
    newValue[index] = newPositionValue

    if (Number.isFinite(parseInt(newPositionValue))) {
      onChange(newValue)
    } else {
      return
    }

    if (index < (inputsRef.current.length - 1)) {
      inputsRef.current[index + 1].focus();
    } else if (index == (inputsRef.current.length - 1)) {
      inputsRef.current[0].focus();
    }
  }

  const eventHandlerKeyDown = (e) => {
    const index = parseInt(e.target.getAttribute("index"))
    const key = e.nativeEvent.key

    if (key === 'Backspace') {
      if (value[index]) {
        let newValue = [...value]
        newValue[index] = ""
        onChange(newValue)
      }
    }

    if (key === "ArrowRight") {
      if (index < (inputsRef.length - 1)) {
        inputsRef.current[index + 1].focus()
      } else if (index === (inputsRef.current.length - 1)) {
        inputsRef.current[0].focus()
      }
    }

    if (key == "ArrowLeft") {
      if (index > 0) {
        inputsRef.current[index - 1].focus()
      } else if (index === 0) {
        inputsRef.current[(inputsRef.current.length - 1)].focus()
      }
    }
  }



  return (
    <div className="pininput">
      {
        value.map((item, index) => {
          return (
            <input
              key={index}
              type='text'
              onChange={eventHandlerChange}
              className="pininput-numberbox"
              value={value[index]}
              index={index}
              placeholder={"_"}
              ref={(ref) => {
                inputsRef.current[index] = ref
              }}
              inputMode={"numeric"}
            />
          )
        })
      }
    </div>
  )
}

PinInput.defaultProps = {
  value: ""
}

export default forwardRef(PinInput)