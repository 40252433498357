import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { getTransportRoute } from '../../store/stationSearchByTypePageSlice'
import { IconArrowForward } from '../../resources/icons'

import './RouteList.scss'
import transliterator from '../../services/transliterator'

const propTypes = {
    transportType: PropTypes.string.isRequired,
}

/* eslint react/prop-types: ["error", { ignore: [transportRoute] }] */
const RouteListBody = ({ transportType, transportRoute }) => {
    const intl = useIntl()

    return (
        <>
            <div className="ssbtp-route-list-subtitle">
                {intl.formatMessage({
                    id: 'pages.stationSearchByType.routeList.title',
                })}
            </div>
            <div className="ssbtp-route-list-content">
                {transportRoute.map((item, i) => (
                    <Link key={item.ord} className="list-item" to={`/station_forecasts?sid=${item.station_id}`}>
                        <div
                            className={classNames('list-item-badge-wrapper', transportType, {
                                first: i === 0,
                                last: i === transportRoute.length - 1,
                            })}
                        >
                            <div className="list-item-badge-wrapper2">
                                <div className="list-iItem-badge" />
                            </div>
                        </div>
                        <div className="list-item-link">
                            <div className="list-item-station-name">{transliterator().transliterate(item.station_name)}</div>
                            <div className="icon-arrow-forward-wrapper">
                                <IconArrowForward />
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    )
}

RouteListBody.propTypes = propTypes

const RouteList = connect(state => ({
    transportRoute: getTransportRoute(state),
}))(RouteListBody)

RouteList.propTypes = propTypes

export default RouteList
