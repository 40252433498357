import axios from 'axios'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { push as navigateTo } from 'connected-react-router'
import { setLoadingStarted, startLoading } from './schoolbusStationSearchByAreaPageSlice'
import dayjs from 'dayjs'
import config from '../resources/config'
import { pinError } from './sharedActions'

const SET_LOADING_TIMEOUT = 1000
const requestTimeout = config.common.backend.requestTimeout

export const SCHOOL_BUS_SCHEDULE_PAGE_SLICE = 'schoolBusSchedulePage'

const schoolBusSchedulePageSlice = createSlice({
    name: SCHOOL_BUS_SCHEDULE_PAGE_SLICE,
    initialState: {
        stationId: null,
        date: null,

        schedules: [],
        schedulesLoading: false,
        schedulesLoaded: false,
        schedulesError: false,
        schedulesTimeout: false,

        stationInfo: {},
        stationInfoLoading: false,
        stationInfoLoaded: false,
        stationInfoError: false,
        stationInfoTimeout: false,
    },
    reducers: {
        pageOpened: (state, { payload: { stationId, date } }) => {
            state.stationId = stationId
            state.date = date
            state.stationInfo = {}

            return state
        },
        pageClosed: state => {
            state.stationId = null
            state.stationInfo = {}
            state.date = null

            return state
        },

        setStationId: (state, { payload }) => {
            state.stationId = payload
        },
        setDate: (state, { payload }) => {
            state.date = payload
        },

        setSchedules: (state, { payload }) => {
            state.schedules = payload
            state.schedulesLoaded = true
            state.schedulesLoading = false
            state.schedulesError = false
            state.schedulesTimeout = false
        },
        setSchedulesLoading: state => {
            state.schedulesLoading = true
        },
        clearSchedules: state => {
            state.schedules = []
            state.schedulesLoaded = false
            state.schedulesLoading = false
            state.schedulesError = false
            state.schedulesTimeout = false
        },
        setSchedulesError: state => {
            state.schedulesLoading = false
            state.schedulesError = true
        },
        clearSchedulesError: state => {
            state.schedulesError = false
        },
        setSchedulesTimeout: state => {
            state.schedulesTimeout = true
        },
        clearSchedulesTimeout: state => {
            state.scheduleTimeout = false
        },

        setStationInfo: (state, { payload }) => {
            state.stationInfo = payload
            state.stationInfoLoaded = true
            state.stationInfoLoading = false
            state.stationInfoError = false
            state.stationInfoTimeout = false
        },
        setStationInfoLoading: state => {
            state.stationInfoLoading = true
        },
        clearStationInfo: state => {
            state.stationInfo = {}
            state.stationInfoLoaded = false
            state.stationInfoLoading = false
            state.stationInfoError = false
            state.stationInfoTimeout = false
        },
        setStationInfoError: state => {
            state.stationInfoLoading = false
            state.stationInfoError = true
        },
        clearStationInfoError: state => {
            state.stationInfoError = false
        },
        setStationInfoTimeout: state => {
            state.stationInfoTimeout = true
        },
        clearStationInfoTimeout: state => {
            state.stationInfoTimeout = false
        },
    },
})

export const {
    pageOpened,
    pageClosed,
    setDate,
    clearSchedulesError,
    clearStationInfoError,
    setSchedulesLoading,
    setStationInfoLoading,
    clearStationInfo,
    clearSchedules,
    clearStationInfoTimeout,
    clearSchedulesTimeout,
} = schoolBusSchedulePageSlice.actions

export const getSchoolBusSchedulePage = state => state.pages[SCHOOL_BUS_SCHEDULE_PAGE_SLICE]
export const getDate = state => getSchoolBusSchedulePage(state).date
export const getStationId = state => getSchoolBusSchedulePage(state).stationId

export const getStationInfo = state => getSchoolBusSchedulePage(state).stationInfo
export const getStationInfoLoaded = state => getSchoolBusSchedulePage(state).stationInfoLoaded
export const getStationInfoLoading = state => getSchoolBusSchedulePage(state).stationInfoLoading
export const getStationInfoError = state => getSchoolBusSchedulePage(state).stationInfoError
export const getStationInfoReady = createSelector(
    [getStationInfoLoaded, getStationInfoError, getStationInfoLoading],
    (loaded, hasError, loading) => loaded && !hasError && !loading,
)
export const getStationInfoTimeout = state => getSchoolBusSchedulePage(state).stationInfoTimeout

export const getSchedules = state => getSchoolBusSchedulePage(state).schedules
export const getSchedulesLoaded = state => getSchoolBusSchedulePage(state).schedulesLoaded
export const getSchedulesLoading = state => getSchoolBusSchedulePage(state).schedulesLoading
export const getSchedulesError = state => getSchoolBusSchedulePage(state).schedulesError
export const getSchedulesReady = createSelector(
    [getSchedulesLoaded, getSchedulesError, getSchedulesLoading],
    (loaded, hasError, loading) => loaded && !hasError && !loading,
)
export const getSchedulesTimeout = state => getSchoolBusSchedulePage(state).schedulesTimeout

export const startSchedulesLoading = () => async dispatch => {
    dispatch(clearSchedulesError())
    dispatch(clearSchedulesTimeout())
    return setTimeout(() => {
        dispatch(setSchedulesLoading())
    }, SET_LOADING_TIMEOUT)
}
export const startStationInfoLoading = () => async dispatch => {
    dispatch(clearStationInfoError())
    dispatch(clearStationInfoTimeout())
    return setTimeout(() => {
        dispatch(setStationInfoLoading())
    }, SET_LOADING_TIMEOUT)
}

export const fetchStationInfo = () => async (dispatch, getState) => {
    const state = getState()
    const stationId = getStationId(state)

    const loadingTimeout = await dispatch(startStationInfoLoading())

    try {
        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }

        const { data } = await axios
            .get('/getStationInfo.php', {
                params: { sid: stationId },
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            })

        if (data.error) {
            if (data.error === 'incorrect pin') {
                dispatch(pinError(data.error))
                throw new Error('Неверный пин')
            } 
            throw new Error("Ошибка ответа сервера")
        }

        if (!data) {
            throw new Error('Неверные данные с сервера')
        }

        const currentState = getState()
        const currentStationId = getStationId(currentState)
        if (currentStationId === stationId) {
            if (data && data.name && data.name.length) {
                dispatch(schoolBusSchedulePageSlice.actions.setStationInfo(data))
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Ошибка получения остановки с id="${stationId}": `, error)
        dispatch(schoolBusSchedulePageSlice.actions.clearStationInfo())
        dispatch(schoolBusSchedulePageSlice.actions.setStationInfoError())
        if (error?.message === 'request timeout') {
            dispatch(schoolBusSchedulePageSlice.actions.setStationInfoTimeout())
        }
    } finally {
        clearTimeout(loadingTimeout)
    }
}

export const fetchSchedules = () => async (dispatch, getState) => {
    const state = getState()
    const sid = getStationId(state)
    const date = getDate(state)

    const loadingTimeout = await dispatch(startSchedulesLoading())

    try {
        const params = { sid: sid, date: date }

        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }
        const { data } = await axios
            .get('/getStationScheduleTable.php', {
                params,
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }
                return result
            })

        //TODO: сделать общий обработчик ошибок?
        if (data.error) {
            if (data.error === 'incorrect pin') {
                dispatch(pinError(data.error))
                throw new Error('Неверный пин')
            } 
            throw new Error("Ошибка ответа сервера")
        }

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        dispatch(schoolBusSchedulePageSlice.actions.setSchedules(data))
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
            `Ошибка получения расписания с параметрами: дата ${
                date ? dayjs(date).format('DD-MM-YYYY') : date
            } id станции ${sid} `,
            error,
        )
        dispatch(schoolBusSchedulePageSlice.actions.clearSchedules())
        dispatch(schoolBusSchedulePageSlice.actions.setSchedulesError())
        if (error?.message === 'request timeout') {
            dispatch(schoolBusSchedulePageSlice.actions.setSchedulesTimeout())
        }
    } finally {
        clearTimeout(loadingTimeout)
    }
}

export default schoolBusSchedulePageSlice.reducer
