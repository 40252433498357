import React from 'react'
import ReactDOMServer from 'react-dom/server'
import Leaflet from 'leaflet'
import { cloneDeep, merge, unionWith } from 'lodash'
import store from '../../store'

const Component = ({ config, dataSource, componentOptions = {} }) => {
    let thisLayerRef = null

    const thisDataSource = dataSource()
    console.log(thisDataSource)

    const defaultOptions = {}
    let onStationClick = null

    let zoom = 1

    const createLayer = ({ mapRef, options }) => {
        thisLayerRef = Leaflet.layerGroup([])
        thisLayerRef.addTo(mapRef)

        onStationClick = options.onStationClick
    }

    const removeLayer = () => {
        thisLayerRef?.remove()
        thisLayerRef = null
    }

    const loadRouteStations = async routeId => {
        if (!thisDataSource) {
            console.error('Не указан источник данных для построения линии маршрута')
            return null
        }
        if (!routeId) {
            console.warn('Не указан маршрут')
            return null
        }
        if (thisDataSource.getLoading()) {
            console.warn('Узлы для построения линии маршрута уже загружаются')
            return null
        }

        await thisDataSource.load({ routeId })
        if (thisDataSource.getError()) {
            console.error('Произошла ошибка загрузки узлов построения маршрута')
            return null
        }

        //TODO: найти способ сделать лучше
        drawStations()
    }

    const drawStations = () => {
        if (!thisLayerRef) {
            return
        }

        thisLayerRef.clearLayers()
        let data = thisDataSource.getLoadedData()

        const mergedData = {}

        for (let index = 0; index < data.length; index++) {
            const currentItem = data[index]

            if (mergedData[currentItem.station_id]) {
                mergedData[currentItem.station_id].routeOrderNumbers.push(currentItem.ord)
            } else {
                mergedData[currentItem.station_id] = cloneDeep(currentItem)
                mergedData[currentItem.station_id].routeOrderNumbers = [currentItem.ord]
            }
        }

        const mergedDataArray = []
        for (let key in mergedData) {
            let tempItem = mergedData[key]
            tempItem.ord = tempItem.routeOrderNumbers.sort((a, b) => a - b).join(',')
            console.log(tempItem)
            if (store.getState().hasSchedule.schedule === false) {
                if (tempItem.ord === '1') {mergedDataArray.push(tempItem)}
            } else {
                mergedDataArray.push(tempItem)
            }
        }

        mergedDataArray.forEach(item => {
            const marker = Leaflet.marker([item.lat, item.lon], {
                icon: Leaflet.divIcon({
                    className: 'station-marker',
                    html: ReactDOMServer.renderToString(
                        <div
                            className={
                                'station-marker_body ' +
                                (((item.dir >= 0 && item.dir <= 180) || (item.dir >= -360 && item.dir <= -180)
                                    ? 'right'
                                    : 'left') +
                                    (zoom <= 14 ? ' short' : ''))
                            }
                        >
                            <div className="station-marker_icon">
                                <div className="station-marker_icon-insert">{item.ord}</div>
                            </div>
                            {zoom > 14 && (
                                <div className="station-marker_message-container">
                                    <div className="station-marker_message">{item.station_name}</div>
                                    <div className="station-marker_message desc">{item.station_description}</div>
                                </div>
                            )}
                        </div>,
                    ),
                }),
                meta: { ...item },
            }).on('click', onStationClick)

            thisLayerRef.addLayer(marker)
        })
    }

    const setZoom = zoomLevel => {
        zoom = zoomLevel
        drawStations()
    }

    const showRouteStations = () => {
        if (thisLayerRef) {
            thisLayerRef.options.opacity = 1
        }
    }

    const hideRouteStations = () => {
        if (thisLayerRef) {
            thisLayerRef.options.opacity = 0
        }
    }

    return {
        createLayer,
        removeLayer,
        loadRouteStations,
        showRouteStations,
        hideRouteStations,
        setZoom,
    }
}

export default Component
