import React from 'react';
import { Link } from 'react-router-dom'
import { IconBack } from "../../resources/icons";

import './BackButton.scss'

export const BackButton = ({ link, title }) => {
  return (
    <Link to={link} className="back-button">
      <IconBack />

      <div className="title back-button__title">{title}</div>
    </Link>
  );
};