import Leaflet from 'leaflet'
import { useSelector } from 'react-redux'
import store from '../../store'

const Component = ({ config, dataSource, componentOptions = {} }) => {
    let thisLayerRef = null

    const thisDataSource = dataSource()

    const defaultOptions = {}

    const defaultColors = {
        outline: 'white',
        core: '#B471DF',
    }

    const createLayer = ({ mapRef }) => {
        // thisLayerRef = Leaflet.polyline(thisDataSource.getLoadedData(), {
        //     ...defaultOptions,
        //     ...componentOptions,
        // })
        // TODO: найти способ сделать лучше

        thisLayerRef = Leaflet.layerGroup([])

        thisLayerRef.addLayer(
            Leaflet.polyline(thisDataSource.getLoadedData(), {
                weight: 10,
                color: defaultColors.outline,
            }),
        )
        thisLayerRef.addLayer(
            Leaflet.polyline(thisDataSource.getLoadedData(), {
                weight: 6,
                color: defaultColors.core,
            }),
        )

        store.getState().hasSchedule.schedule && thisLayerRef.addTo(mapRef)
        console.log('routelayer', store.getState().hasSchedule.schedule)
    }

    const removeLayer = () => {
        thisLayerRef?.remove()
        thisLayerRef = null
    }

    const loadRouteLine = async routeId => {
        if (!thisDataSource) {
            console.error('Не указан источник данных для построения линии маршрута')
            return null
        }
        if (!routeId) {
            console.warn('Не указан маршрут')
            return null
        }
        if (thisDataSource.getLoading()) {
            console.warn('Узлы для построения линии маршрута уже загружаются')
            return null
        }

        await thisDataSource.load({ routeId })
        if (thisDataSource.getError()) {
            console.error('Произошла ошибка загрузки узлов построения маршрута')
            return null
        }

        thisLayerRef.eachLayer(layer => layer.setLatLngs(thisDataSource.getLoadedData()))
    }

    const showRouteLine = () => {
        if (thisLayerRef) {
            thisLayerRef.eachLayer(layer => (layer.options.opacity = 1))
        }
    }

    const hideRouteLine = () => {
        if (thisLayerRef) {
            thisLayerRef.eachLayer(layer => (layer.options.opacity = 0))
        }
    }

    const getBounds = () => {
        if (thisLayerRef) {
            return thisLayerRef.getLayers()[0].getBounds()
        } else {
            return null
        }
    }

    const setColors = (core, outline) => {
        if (thisLayerRef) {
            if (outline) {
                thisLayerRef.getLayers()[0].options.color = outline
            }
            if (core) {
                thisLayerRef.getLayers()[1].options.color = core
            }

            if (!core && !outline) {
                thisLayerRef.getLayers()[0].options.color = defaultColors.outline
                thisLayerRef.getLayers()[1].options.color = defaultColors.core
            }

        }
    }

    return {
        createLayer,
        removeLayer,
        loadRouteLine,
        showRouteLine,
        hideRouteLine,
        getBounds,
        setColors,
    }
}

export default Component
