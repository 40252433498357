import axios from 'axios'
import config from '../resources/config'
const requestTimeout = config.common.backend.requestTimeout

const RouteStationsDataSource = () => {
    let stations = []
    let stationsLoading = false
    let stationsError = false

    const load = async ({ routeId }) => {
        stationsError = false
        stationsLoading = true

        if (!routeId) {
            stationsLoading = false
            stations = []
            return []
        }

        let params = { id: routeId }

        const cancelationToken = axios.CancelToken.source()
        let timeout = null
        if (requestTimeout) {
            timeout = setTimeout(() => {
                cancelationToken.cancel('request timeout')
            }, requestTimeout)
        }

        const { data } = await axios
            .get('/getRouteStations.php', {
                params,
                cancelToken: cancelationToken.token,
            })
            .then(result => {
                if (timeout) {
                    clearTimeout(timeout)
                }

                return {
                    ...result,
                    data: result.data.map(item => {
                        return {
                            ...item,
                            lat: parseInt(item.lat) / 1000000,
                            lon: parseInt(item.lon) / 1000000,
                        }
                    }),
                }
            })
            .catch(error => {
                console.log('Ошибка получения станций маршрута: ' + error)
                return { data: [] }
            })

        stationsLoading = false
        stations = data

        return data
    }

    const getLoadedData = () => {
        return stations
    }

    const unloadData = () => {
        stations = []
    }

    const getLoading = () => {
        return stationsLoading
    }

    const getError = () => {
        return stationsError
    }

    return { load, getLoadedData, unloadData, getLoading, getError }
}

export default RouteStationsDataSource
