import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import translit from "./../../services/transliterator"

import { getStationInfo } from '../../store/schoolBusSchedulePageSlice'
import { IconStation } from '../../resources/icons'

import './StationInfo.school.scss'


/* eslint react/prop-types: ["off"] */
const StationInfo = ({ stationInfo }) => {
    const { name, description, routeTypes = [] } = stationInfo || {}

    return (
        <div className="sfp-station-info-wrapper">
            <IconStation routeTypes={routeTypes} iconType="place" />
            <div className="text-wrapper">
                <div className="title">
                    {translit().transliterate(name)}
                </div>
                <div className="description">{translit().transliterate(description)}</div>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        stationInfo: getStationInfo(state),
    }),
    ({

    })
)(StationInfo)
